import React from "react"
import { css } from "glamor"
import { withTranslation, WithTranslation } from "react-i18next"
import { ThemeProps, theme } from "@basset-la/themed-components"
import { getReservation } from "../api/reservations"
import { getAccommodationReservation, getAccommodation } from "../api/accommodations"
import { formatPriceWeb, getLocale } from "@basset-la/components-products/dist/utils/helpers"
import moment from "moment"
import "moment/locale/es"
import AccommodationCluster from "@basset-la/commons-frontend/dist/components/accommodations/AccommodationCluster"
import CancelPenalties from "./accommodations/CancelPenalties"
import AccommodationDescription from "./accommodations/AccommodationDescription"
import { RouteComponentProps } from "react-router"
import { I18N_NS } from "../utils/constants"
import Check from "../icons/Check"
import { AuthUserType, withAuthUser, withConfig } from "@basset-la/components-commons"
import AppLoader from "@basset-la/components-commons/dist/components/AppLoader"
import { ConfigType } from "@basset-la/components-commons/dist/components/ConfigProvider/ConfigProvider"

interface MatchProps {
  id: string
}

interface Props extends WithTranslation, ThemeProps, RouteComponentProps<MatchProps>, AuthUserType, ConfigType {}

class AccommodationsThanks extends React.PureComponent<Props, any> {
  constructor(props: Props) {
    super(props)
    this.state = {
      reservation: null,
      accommodation: null,
      site: ""
    }
  }

  componentDidMount() {
    const lang = getLocale(this.props.i18n).substring(0, 2)
    moment.locale(lang)
  }

  UNSAFE_componentWillMount() {
    this.getReservation()
  }

  getReservation = async () => {
    const id = this.props.match.params.id
    const lang = getLocale(this.props.i18n).substring(0, 2)
    const res = await getReservation(id, this.props.userId, this.props.config.country, this.props.config.agency_id)
    const accommodationReservation = await getAccommodationReservation(
      res.products[0].reservation_id,
      this.props.config.country,
      this.props.config.agency_id
    )
    const accommodation = await getAccommodation(
      accommodationReservation.accommodation.id,
      lang,
      this.props.config.agency_id
    )

    this.setState({
      reservation: accommodationReservation,
      accommodation: accommodation,
      site: this.props.config.country
    })
  }

  totalStyle = css({
    fontSize: "18px",
    fontWeight: "300",
    color: this.props.theme.colors.brand_primary,
    lineHeight: "1.33",
    " > span": {
      fontWeight: "500"
    }
  })

  render() {
    const { reservation, accommodation } = this.state
    const { t, i18n, theme } = this.props
    const locale = getLocale(i18n)

    const titleTextStyle = css({
      fontFamily: "Roboto",
      fontSize: "24px",
      fontWeight: "300",
      lineHeight: "1.33",
      margin: 0,
      color: theme.colors.brand_primary,
      marginLeft: "8px !important"
    })

    if (reservation === null || accommodation === null) return <AppLoader variant="ACCOMMODATIONS" />

    const checkin = moment(reservation.accommodation.rate.checkin)
    const checkout = moment(reservation.accommodation.rate.checkout)
    const nights = checkout.diff(checkin, "days")
    const checkinDate = checkin.utc().format("ddd DD MMM YYYY")
    const checkoutDate = checkout.utc().format("ddd DD MMM YYYY")
    const { rooms, comments } = reservation.accommodation.rate

    const refundable = reservation.accommodation.rate.refundable

    const reservationDetailTitle = css({
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "1.5",
      color: theme.colors.brand_primary
    })

    let checkinInstructions = ""
    if (accommodation.checkin.instructions) {
      checkinInstructions += accommodation.checkin.instructions
    }

    if (accommodation.checkin.special_instructions) {
      checkinInstructions += accommodation.checkin.special_instructions
    }

    checkinInstructions += t("AccommodationsThanks.checkin_instructions_disclamer")

    return (
      <div className={`${containerStyle}`}>
        <div className={`${titleStyle}`}>
          <div className={`${checkIconStyle}`}>
            <Check />
          </div>
          <p className={`${titleTextStyle}`}>{t<string>("AccommodationsThanks.title")}</p>
        </div>
        <div className={`${fistSectionStyle}`}>
          <div className={`${sectionStyle}`}>
            <p className={`${this.totalStyle}`}>{t<string>("AccommodationsThanks.total")}</p>
            <p className={`${totalPriceStyle}`}>
              {formatPriceWeb(
                reservation.accommodation.rate.fare.total,
                locale,
                reservation.accommodation.rate.fare.currency
              )}
            </p>
          </div>
          <div className={`${sectionStyle}`} style={{ maxWidth: "432px" }}>
            <p className={`${this.totalStyle}`}>
              {t<string>("AccommodationsThanks.reservationNumber")} <span>{this.props.match.params.id}</span>
            </p>
            {reservation.contact && (
              <p className={`${emailTextStyle}`}>
                {t<string>("AccommodationsThanks.ticket")}
                <span>{reservation.contact.email}</span>
              </p>
            )}
          </div>
          <div className={`${sectionStyle}`}>
            <p className={`${this.totalStyle}`}>{t<string>("AccommodationsThanks.hotelCode")}</p>
            <p className={`${totalPriceStyle}`}>{reservation.provider_reservation_id}</p>
          </div>
        </div>
        <div className={`${reservationDetailContainer}`}>
          <p className={`${reservationDetailTitle}`}>{t<string>("AccommodationsThanks.detail")}</p>
          <p className={`${subTitleStyle}`}>
            <span>{`${t<string>("AccommodationsThanks.nights", { count: nights })}, ${t<string>(
              "AccommodationsThanks.rooms",
              {
                count: rooms.length
              }
            )}`}</span>
          </p>
          <div className={`${reservationDetailStyle}`}>
            <div style={{ maxWidth: "400px" }}>
              <AccommodationCluster
                name={accommodation.name}
                address={accommodation.address}
                stars={accommodation.stars}
                rating={accommodation.rating}
                mealPlan={t(`Accommodation.mealPlan.${reservation.accommodation.rate.meal_plan}`)}
                photos={accommodation.images}
                mini
                borderless
                refundable={refundable}
              />
            </div>
            <div className={`${reservationBreakdownContainer}`}>
              <div className={`${datesRowContainer}`} style={{ borderBottom: "1px solid #ccc", paddingTop: 0 }}>
                <p>{t<string>("AccommodationsThanks.arrival")}</p>
                <p>
                  {checkinDate} {`(${t<string>("AccommodationsThanks.since")} ${accommodation.checkin.time})`}
                </p>
              </div>
              <div className={`${datesRowContainer}`}>
                <p>{t<string>("AccommodationsThanks.departure")}</p>
                <p>
                  {checkoutDate} {`(${t<string>("AccommodationsThanks.until")} ${accommodation.checkout.time})`}
                </p>
              </div>
              <div className={`${guestsContainer}`}>
                <p className={`${detailTitleStyle}`}>{t<string>("AccommodationsThanks.guest_per_room")}</p>
                {rooms.map((r, i) => (
                  <div key={i} className={`${guestRow}`}>
                    <p>{r.name}</p>
                    <p>
                      {r.guest.firstname} {r.guest.lastname} -{" "}
                      {t(`AccommodationsThanks.documentTypes.${r.guest.document.type}`)}: {r.guest.document.number}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={`${reservationDetailContainer}`}>
          {checkinInstructions.length > 0 && (
            <div>
              <AccommodationDescription
                title={t<string>("AccommodationsThanks.checkin_instructions")}
                description={checkinInstructions}
              />
            </div>
          )}
          <div>
            <CancelPenalties
              cancelPenalties={reservation.accommodation.rate.cancel_penalties}
              expirationDate={reservation.accommodation.rate.expiration_date}
              customExpirationDate={reservation.accommodation.rate.custom_expiration_date}
              refundable={refundable}
              site={reservation.site}
            />
          </div>
          {comments.length > 0 && (
            <div>
              <AccommodationDescription
                title={t<string>("AccommodationsThanks.rate_comments")}
                description={comments}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

const guestsContainer = css({
  marginTop: "16px"
})

const reservationBreakdownContainer = css({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: "16px",
  "@media(min-width:1200px)": {
    width: "400px",
    marginTop: 0
  }
})

const detailTitleStyle = css({
  fontSize: "14px",
  textTransform: "uppercase",
  lineHeight: "1.14",
  color: "#9b9b9b",
  fontWeight: "400"
})

const detailDescriptionStyle = css({
  fontSize: "14px",
  fontWeight: "400",
  color: "#4a4a4a",
  minWidth: "240px",
  textAlign: "right"
})

const guestRow = css({
  display: "flex",
  flexDirection: "row",
  padding: "8px 0",
  borderBottom: "1px solid #ccc",
  "&:last-child": {
    borderBottom: 0
  },
  justifyContent: "space-between",
  " >p:first-child": {
    fontSize: "14px",
    fontWeight: "300",
    color: "#4a4a4a",
    lineHeight: "1.14"
  },
  " >p:last-child": detailDescriptionStyle
})

const datesRowContainer = css({
  display: "flex",
  flexDirection: "row",
  padding: "8px 0",
  justifyContent: "space-between",
  " >p:first-child": detailTitleStyle,
  " >p:last-child": detailDescriptionStyle
})

const reservationDetailStyle = css({
  display: "flex",
  flexDirection: "column",
  marginTop: "16px",
  "@media(min-width:1200px)": {
    flexDirection: "row",
    justifyContent: "space-between"
  }
})

const subTitleStyle = css({
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "1.14",
  color: "#4a4a4a"
})

const reservationDetailContainer = css({
  display: "flex",
  flexDirection: "column",
  padding: "16px",
  marginTop: "16px",
  border: "1px solid #ccc",
  borderRadius: "3px",
  backgroundColor: "#fff"
})

const titleStyle = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "8px"
})

const checkIconStyle = css({
  width: "24px",
  height: "24px",
  marginTop: "4px",
  backgroundSize: "contain"
})

const containerStyle = css({
  display: "flex",
  flexDirection: "column",
  margin: "0 auto",
  maxWidth: "880px",
  " p": {
    //fix
    margin: 0
  }
})

const fistSectionStyle = css({
  display: "flex",
  flexDirection: "column",
  "@media(min-width:1200px)": {
    flexDirection: "row",
    " > div": {
      marginBottom: 0,
      marginRight: "16px"
    },
    " > div:last-child": {
      marginBottom: 0,
      marginRight: 0
    }
  },
  "> div:first-child": {
    "@media(min-width:1200px)": {
      width: "208px"
    }
  },
  " > div": {
    marginBottom: "8px"
  },
  " > div:last-child": {
    marginBottom: 0,
    "@media(min-width:1200px)": {
      width: "208px"
    }
  },
  " p": {
    margin: 0
  }
})

const emailTextStyle = css({
  fontSize: "14px",
  color: "#4a4a4a",
  lineHeight: "1.14",
  fontWeight: "300",
  " > span": {
    fontWeight: "500"
  }
})

const totalPriceStyle = css({
  color: "#4a4a4a",
  lineHeight: "1.33",
  fontWeight: "500"
})

const sectionStyle = css({
  display: "flex",
  flexDirection: "column",
  padding: "16px",
  border: "1px solid #ccc",
  backgroundColor: "#fff",
  borderRadius: "3px"
})

export default withConfig(withAuthUser(withTranslation(I18N_NS)(theme(AccommodationsThanks))))
