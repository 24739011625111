// @flow

import React from "react"
import { css } from "glamor"
import { withTranslation, WithTranslation } from "react-i18next"
import { formatPrice } from "../../utils/flightsUtils"
import { I18N_NS } from "../../utils/constants"

interface Props extends WithTranslation {
  fees: any[]
  site: string
}

const Fees = ({ fees, t }: Props) => {
  return (
    <div className={`${containerStyle}`}>
      <p className={`${titleStyle}`}>{t<string>("Fees.notIncluded")}:</p>
      <div>
        {fees.map((f, i) => (
          <p key={i}>
            - {t<string>(`Fees.types.${f.type}`)}: {`${f.currency} ${formatPrice(f.amount)}`}
          </p>
        ))}
      </div>
    </div>
  )
}

const titleStyle = css({
  fontWeight: "400",
  marginBottom: "8px"
})

const containerStyle = css({
  padding: "8px",
  display: "flex",
  flexDirection: "column",
  borderRadius: "3px",
  backgroundColor: "#ebebeb",
  " p": {
    margin: 0,
    fontSize: "14px",
    color: "#4a4a4a",
    letterSpacing: "0.1px",
    fontWeight: "300"
  }
})

export default withTranslation(I18N_NS)(Fees)
