import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import FormSection from "../common/FormSection"
import InsuranceCrosselling from "@basset-la/components-insurances/dist/components/Crosselling"
import { CrossSellingCluster } from "../types"
import { fetchClusters, getInsuranceDetail } from "../../api/insurances"
import {
  Insurance,
  SearchboxParams as InsuranceSearchboxParams,
  useOpenInsuranceDetails
} from "@basset-la/components-insurances"
import styles from "./InsurancesCrossSelling.styles"
import { useConfig } from "@basset-la/components-commons"
import LinearProgress from "@basset-la/components-commons/dist/components/LinearProgress"
import { CheckoutEmergencyContact, InsurancesProviderConfig } from "../../utils/common"
import { getProviderConfig } from "../../api/common"

const INSURANCE_SEARCH_LIMIT = 20

export interface Props {
  selectedCluster?: CrossSellingCluster
  lastSelectedClusterProviderConfig?: InsurancesProviderConfig
  insuranceSearchParams?: InsuranceSearchboxParams
  emergencyContact?: CheckoutEmergencyContact
  onClusterSelected: (cluster: CrossSellingCluster, c: InsurancesProviderConfig) => void
  onChangeEmergencyContact: (c: CheckoutEmergencyContact) => void
}

const InsurancesCrossSelling: React.FC<Props> = ({
  insuranceSearchParams,
  emergencyContact,
  onClusterSelected,
  onChangeEmergencyContact,
  lastSelectedClusterProviderConfig
}) => {
  const { t } = useTranslation(I18N_NS)
  const { config } = useConfig()

  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState<Insurance[]>([])

  const {
    loadingDetails,
    onGetClusterDetail,
    onCloseDetails,
    onOpenDetails,
    openDetails
  } = useOpenInsuranceDetails((id, site, channel, language) =>
    getInsuranceDetail(id, site, channel, language, config.agency_id)
  )

  const [openDetailsInsuranceId, setOpenDetailsInsuranceId] = useState<string>()

  useEffect(() => {
    const validateInsuranceSearch = (): boolean => {
      if (!insuranceSearchParams?.passengers?.birthday) return false
      const isValid = insuranceSearchParams?.passengers.birthday.every(
        (date: moment.Moment) => date?.isValid() && date?.year()
      )
      return isValid
    }

    const fetchInsurances = async () => {
      setLoading(true)

      try {
        const results = await fetchClusters(
          insuranceSearchParams!,
          { page: 0, offset: 0, limit: INSURANCE_SEARCH_LIMIT },
          config,
          true
        )

        setItems(results.insurances)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }

    if (!insuranceSearchParams) return
    if (!validateInsuranceSearch()) return

    fetchInsurances()
  }, [insuranceSearchParams])

  const handleFetchInsuranceDetail = async (insuranceId: string): Promise<void> => {
    if (!items.length) {
      return
    }

    const productIndex = items.findIndex(i => i.id === insuranceId)

    if (productIndex === -1) {
      return
    }

    try {
      const product = items[productIndex]

      if (product.coverages?.length) {
        onOpenDetails(insuranceId)
        setOpenDetailsInsuranceId(insuranceId)
        return
      }

      const detail = await onGetClusterDetail(product.id, config.country, "WEB", config.language)

      setItems([...items.slice(0, productIndex), detail, ...items.slice(productIndex + 1)])
      setOpenDetailsInsuranceId(insuranceId)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSelectInsurance = async (idx: number) => {
    let defaultProviderConfig: InsurancesProviderConfig = {
      emergency_contact_required: false,
      id: ""
    }

    const cluster = items[idx]

    if (!cluster) {
      onClusterSelected(
        { productType: "TRAVEL_INSURANCES" },
        lastSelectedClusterProviderConfig ?? defaultProviderConfig
      )
      return
    }

    if (cluster.provider_configuration_id === lastSelectedClusterProviderConfig?.id) {
      onClusterSelected({ productType: "TRAVEL_INSURANCES", cluster: cluster }, lastSelectedClusterProviderConfig)
      return
    }

    try {
      setLoading(true)
      defaultProviderConfig = await getProviderConfig(cluster.provider_configuration_id, config.agency_id)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }

    onClusterSelected({ productType: "TRAVEL_INSURANCES", cluster: cluster }, defaultProviderConfig)
  }

  const handleOnCloseDetailsInsurance = () => {
    if (openDetailsInsuranceId) {
      onCloseDetails(openDetailsInsuranceId)
      setOpenDetailsInsuranceId(undefined)
    }
  }

  return (
    <div className={styles.root}>
      {loading && <LinearProgress />}
      {items.length > 0 && emergencyContact && (
        <FormSection title={t<string>("CrossSelling.addInsurance")}>
          <InsuranceCrosselling
            variant="WEB"
            items={items}
            emergencyContact={emergencyContact}
            loadingDetail={loadingDetails}
            onUpdatedSelected={handleSelectInsurance}
            onClusterDetail={handleFetchInsuranceDetail}
            onChangeEmergencyContact={onChangeEmergencyContact}
            openDetails={openDetails}
            onCloseDetails={handleOnCloseDetailsInsurance}
            showEmergencyContact={lastSelectedClusterProviderConfig?.emergency_contact_required}
          />
        </FormSection>
      )}
    </div>
  )
}

export default InsurancesCrossSelling
