import i18next from "i18next"
import { checkoutConfig } from "@basset-la/components-products/dist/utils/config"
import { providers } from "./constants"
import { validations } from "@basset-la/commons-frontend"

export const createAccommodationPurchaseModel = (
  rateID: string,
  rooms: any[],
  paymentInfo: any,
  billingInfo: any,
  contact: any,
  site: string,
  formConfig: any,
  lang: string,
  roomIds: string[],
  roomCompanionsMap: any
) => {
  let guestRooms: any[] = []
  let payment: any = null
  let fiscalIndetification: any = null
  let fiscalData: any = null
  let contactInfo: any = null
  let roomCompanions: any[] | undefined = undefined

  if (formConfig.passengers) {
    guestRooms = rooms.map((r, index) => {
      return {
        id: `${roomIds[index]}_${index}`,
        firstname: r.firstName,
        lastname: r.lastName,
        document: {
          type: r.documentType,
          number: r.documentNumber
        }
      }
    })

    if (roomCompanionsMap && Object.values(roomCompanionsMap).length) {
      roomCompanions = []
      for (let key in roomCompanionsMap) {
        roomCompanionsMap[key].forEach(c =>
          roomCompanions?.push({
            firstname: c.firstName,
            lastname: c.lastName,
            document: {
              type: c.documentType,
              number: c.documentNumber
            },
            // age: moment().diff(c.birthday, "years"),
            room_id: roomIds[key] ? `${roomIds[key]}_${key}` : ""
          })
        )
      }
    }
  }

  if (formConfig.payment) {
    payment = {
      type: "EPS",
      token: paymentInfo.token,
      card_holder: paymentInfo.card_holder,
      document: {
        type: paymentInfo.document_type,
        number: paymentInfo.document_number
      },
      expiration_month: paymentInfo.expiration_month.toString(),
      expiration_year: paymentInfo.expiration_year.toString()
    }
  }

  if (formConfig.billingInfo) {
    //@deprecate use fiscal_data
    const siteConfig = checkoutConfig.config[site as keyof typeof checkoutConfig]
    const typeFiscal =
      siteConfig.billing.identification
        .find(o => o.name === "fiscal_situation")
        ?.options?.find(ft => ft.value === billingInfo.fiscal_situation)?.code ?? ""

    fiscalIndetification = {
      type: typeFiscal,
      number: billingInfo.fiscal_id
    }
    fiscalData = {
      identification: {
        type: typeFiscal,
        number: billingInfo.fiscal_id,
        fiscal_name: billingInfo.fiscal_name,
        tax_situation: billingInfo.fiscal_situation
      },
      address: {
        country: billingInfo.country ? billingInfo.country : site,
        state: billingInfo.province,
        city: billingInfo.city,
        street: billingInfo.street,
        number: billingInfo.street_number,
        floor: billingInfo.floor_number,
        door: billingInfo.building_number,
        postal_code: billingInfo.postal_code
      }
    }
  }

  if (formConfig.contactInfo) {
    contactInfo = {
      language: lang,
      email: contact.email,
      telephone: {
        type: contact.telephone_type,
        country_code: contact.telephone_country_code,
        area_code: contact.telephone_area_code,
        number: contact.telephone_number
      }
    }
  }

  return {
    products: [
      {
        item_id: rateID,
        type: "ACCOMMODATION",
        rooms: guestRooms,
        room_companions: roomCompanions
      }
    ],
    payment: payment,
    //@deprecate use fiscal_data
    fiscal_identification: fiscalIndetification,
    fiscal_data: fiscalData,
    contact: contactInfo,
    autocomplete_billing_info: formConfig.autocompleteBillingInfo
  }
}

export const validateNameLength = (t: i18next.TFunction, rateCluster: any, roomGuests: any): boolean => {
  const maxCharactersNameTBO = parseInt(process.env.REACT_APP_ACCOMODATIONS_PROVIDER_TBO_TRIM_NAME!)
  const maxCharactersLastNameTBO = parseInt(process.env.REACT_APP_ACCOMODATIONS_PROVIDER_TBO_TRIM_LAST_NAME!)

  const rate = rateCluster.rates[0]

  if (rate?.provider !== providers?.TBO) return true

  let valid = true

  for (let roomGuest of roomGuests) {
    roomGuest.firstName_error = ""
    roomGuest.lastName_error = ""

    if (roomGuest.firstName.length > maxCharactersNameTBO) {
      const errorMessage = t("AccommodationsCheckout.sizeNameError", {
        maxCharacters: maxCharactersNameTBO
      })
      roomGuest.firstName_error = errorMessage
      valid = false
    }

    if (roomGuest.lastName.length > maxCharactersLastNameTBO) {
      const errorMessage = t("AccommodationsCheckout.sizeLastNameError", {
        maxCharacters: maxCharactersLastNameTBO
      })
      roomGuest.lastName_error = errorMessage
      valid = false
    }
  }

  return valid
}

export const validateRoomCompanionsSection = (
  roomCompanionsMapCopy: any,
  roomCompanionErrorsCopy: string[],
  currentRateCluster: any,
  site: string,
  t: i18next.TFunction
): boolean => {
  let valid = true

  for (const roomNumber in roomCompanionsMapCopy) {
    for (let roomCompanion of roomCompanionsMapCopy[roomNumber]) {
      if (!roomCompanion.firstName) {
        roomCompanion.firstName_error = t("AccommodationsCheckout.firstNameError")
        valid = false
      }
      if (!validations.validateAlphabetic(roomCompanion.firstName)) {
        roomCompanion.firstName_error = t("AccommodationsCheckout.firstNameError")
        valid = false
      }

      if (!roomCompanion.lastName) {
        roomCompanion.lastName_error = t("AccommodationsCheckout.lastNameError")
        valid = false
      }
      if (!validations.validateAlphabetic(roomCompanion.lastName)) {
        roomCompanion.lastName_error = t("AccommodationsCheckout.lastNameError")
        valid = false
      }

      if (!roomCompanion.documentType) {
        roomCompanion.documentType_error = t("AccommodationsCheckout.documentTypeError")
        valid = false
      }
      if (!roomCompanion.documentNumber) {
        roomCompanion.documentNumber_error = t("AccommodationsCheckout.documentNumberError")
        valid = false
      }
      if (roomCompanion.documentType && roomCompanion.documentNumber) {
        if (
          !validations.validateDocumentNumber(
            site,
            roomCompanion.documentNumber,
            roomCompanion.documentType,
            roomCompanion.birthYear
          )
        ) {
          roomCompanion.documentNumber_error = t("AccommodationsCheckout.documentNumberInvalid")
          valid = false
        }
      }
    }
  }

  const isValidDistribution = validateRoomDistribution(
    roomCompanionsMapCopy,
    roomCompanionErrorsCopy,
    currentRateCluster,
    t
  )

  if (!isValidDistribution) {
    valid = false
  }

  return valid
}

const validateRoomCapacities = (roomCapacities: number, companionsCount: number): boolean => {
  if (companionsCount === 0) {
    return true
  }

  return roomCapacities - 1 === companionsCount
}

const validateRoomDistribution = (
  roomCompanionsMapCopy: any,
  roomCompanionErrors: string[],
  currentRateCluster: any,
  t: i18next.TFunction
): boolean => {
  let valid = true

  for (let roomNumber = 0; roomNumber < currentRateCluster.rooms.length; roomNumber++) {
    roomCompanionErrors[roomNumber] = ""
    const isValidCapacity = validateRoomCapacities(
      currentRateCluster.rooms[roomNumber].capacity,
      roomCompanionsMapCopy[roomNumber].length
    )

    if (!isValidCapacity) {
      roomCompanionErrors[roomNumber] = t("AccommodationsCheckout.wrong_capacity")
      valid = false
    }
  }

  return valid
}
