import React from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { formatPrice } from "../../utils/flightsUtils"
import AccommodationDescription from "./AccommodationDescription"
import { I18N_NS } from "../../utils/constants"
import moment from "moment"

interface Props extends WithTranslation {
  refundable: boolean
  cancelPenalties: any[]
  site: string
  expirationDate: string
  customExpirationDate: string
}

const CancelPenalties = ({ refundable, cancelPenalties, t, expirationDate, customExpirationDate }: Props) => {
  let policyText = ""
  if (!refundable) {
    policyText = t("CancelPenalties.notRefundable")
  } else {
    let date = moment(expirationDate).format("DD/MM/YYYY")
    if (customExpirationDate) date = moment(customExpirationDate).format("DD/MM/YYYY")

    let time = moment(expirationDate).format("HH:mm A")
    if (customExpirationDate) time = moment(customExpirationDate).format("HH:mm A")

    policyText += t("CancelPenalties.refundable1", {
      date: date,
      time: time
    })
    cancelPenalties.forEach(p => {
      if (p.nights && p.nights > 0) {
        policyText += t("CancelPenalties.refundable2Night", {
          count: p.nights,
          start_date: moment(p.start).format("DD/MM/YYYY"),
          start_time: moment(p.start).format("HH:mm A"),
          end_date: moment(p.end).format("DD/MM/YYYY"),
          end_time: moment(p.end).format("HH:mm A")
        })
      } else {
        if (p.amount && p.amount > 0) {
          policyText += t("CancelPenalties.refundable2Price", {
            price: `${p.currency_code} ${formatPrice(p.amount)}`,
            start_date: moment(p.start).format("DD/MM/YYYY"),
            start_time: moment(p.start).format("HH:mm A"),
            end_date: moment(p.end).format("DD/MM/YYYY"),
            end_time: moment(p.end).format("HH:mm A")
          })
        } else {
          if (p.percent) {
            policyText += t("CancelPenalties.refundable2Percentage", {
              percentage: p.percent,
              start_date: moment(p.start).format("DD/MM/YYYY"),
              start_time: moment(p.start).format("HH:mm A"),
              end_date: moment(p.end).format("DD/MM/YYYY"),
              end_time: moment(p.end).format("HH:mm A")
            })
          }
        }
      }
    })
    policyText += t("CancelPenalties.refundable3")
  }
  return <AccommodationDescription title={t<string>("CancelPenalties.cancelPolicies")} description={policyText} />
}

export default withTranslation(I18N_NS)(CancelPenalties)
