import React from "react"
import qs from "qs"
import { css } from "glamor"
import FormSection from "./common/FormSection"
import { withTranslation, WithTranslation } from "react-i18next"
import { validations } from "@basset-la/commons-frontend"
import { ThemeProps, theme } from "@basset-la/themed-components"
import Button from "@material-ui/core/Button"
import ContactContainer from "./contact/ContactContainer"
import BillingContainer from "@basset-la/commons-frontend/dist/components/checkout/billing/BillingContainer"
import { getCountries, getStatesByCountryCode, getCitiesByCountryCode } from "../api/common"
import { getRate, getAccommodation } from "../api/accommodations"
import { formatPriceWeb, getLocale } from "@basset-la/components-products/dist/utils/helpers"
import AccommodationDetail from "./accommodations/AccommodationDetail"
import clone from "lodash/clone"
import moment from "moment"
import {
  createAccommodationPurchaseModel,
  validateNameLength,
  validateRoomCompanionsSection
} from "../utils/accommodationsUtils"
import { createReservationAsync, getReservationProcess } from "../api/reservations"
import Fees from "./accommodations/Fees"
import Checkbox from "@material-ui/core/Checkbox"
import NotFound from "./NotFound"
import TermsAndConditions from "./TermsAndConditions"
import { getFormConfig } from "../config/formConfig"
import { RouteComponentProps } from "react-router"
import * as checkoutConfig from "./config/checkoutConfig.json"
import { I18N_NS } from "../utils/constants"
import PriceboxBreakdownCheckoutWeb from "@basset-la/components-accommodations/dist/components/PriceboxBreakdownCheckoutWeb"
import ReactGA from "react-ga"
import ProductLoader from "@basset-la/components-commons/dist/components/ProductLoader"
import { AuthUserType, withAuthUser, withConfig } from "@basset-la/components-commons"
import { ConfigType } from "@basset-la/components-commons/dist/components/ConfigProvider/ConfigProvider"
import Message from "@basset-la/components-commons/dist/components/Message"
import CommonCheckoutRoomSection from "./common/CommonCheckoutRoomSection"
import styles from "./AccommodationsCheckout.styles"
import WarningsDialog from "./WarningsDialog/WarningsDialog"

const EXCLUDED_FEE_TYPES = ["PAIS", "RG4815/20", "RG5272/22", "ADDITIONAL_FEE"]

interface MatchProps {
  id: string
}

interface Props extends WithTranslation, ThemeProps, RouteComponentProps<MatchProps>, AuthUserType, ConfigType {}

class AccommodationsCheckout extends React.PureComponent<Props, any> {
  isMobile = window.innerWidth < 768

  constructor(props: Props) {
    super(props)
    this.state = {
      isFetching: true,
      isBooking: false,
      billingInfo: null,
      notFound: "",
      contactInfo: null,
      site: this.props.config.country,
      termAndConditions: this.props.config.brand.terms_and_conditions,
      roomGuests: [],
      rateCluster: null,
      accommodation: null,
      geo: null,
      paymentsURL: null,
      showTermAndConditions: false,
      termsAndConditionAccepted: false,
      formConfig: getFormConfig(this.props.config.checkout_form_config),
      agencyId: this.props.config.agency_id,
      openChangeFareDialog: false,
      warningsDialogStatus: ""
    }
  }

  UNSAFE_componentWillMount() {
    this.retrieveCheckoutData()
  }

  retrieveCheckoutData = async () => {
    const lang = getLocale(this.props.i18n).substring(0, 2)
    const { site, agencyId } = this.state

    let rateCluster

    try {
      rateCluster = await getRate(this.props.match.params.id, agencyId, site)
    } catch (error) {
      this.setState({ notFound: "notFound", isFetching: false })
    }

    if (rateCluster) {
      let [countries, states, cities] = await Promise.all([
        getCountries(agencyId, lang),
        getStatesByCountryCode(site, agencyId, lang),
        getCitiesByCountryCode(site, agencyId, lang)
      ]) // FIXME: id hardcoded

      const accommodation = await getAccommodation(rateCluster.rates[0].accommodation_id, lang, agencyId)

      const roomCompanionsMap: Record<number, any[]> = {}
      rateCluster.rooms.forEach((_, index) => (roomCompanionsMap[index] = []))

      this.setState({
        paymentsURL: rateCluster ? rateCluster.payments_url : undefined,
        isFetching: false,
        roomGuests: this.createRoomGuests(rateCluster.rooms),
        billingInfo: this.createBillingInformation(),
        contactInfo: this.createContactInformation(),
        geo: {
          countries: countries,
          states: states,
          cities: cities
        },
        rateCluster: rateCluster,
        accommodation: accommodation,
        roomCompanionsMap: roomCompanionsMap,
        roomCompanionErrors: []
      })

      const isPriceChange =
        rateCluster!.rates[0].status === "PRICE_CHANGE" &&
        Math.round(rateCluster!.rates[0].fare.total) === Math.round(rateCluster!.oldFare.total)
      const isCancellationPolicyChange =
        rateCluster!.rates[0].status === "EXPIRATION_DATE_CHANGE" ||
        rateCluster!.rates[0].status === "REFUNDABLE_CHANGE"
      const isSoldOut = rateCluster!.rates[0].status === "SOLD_OUT"

      if (isPriceChange) {
        this.setState({ openChangeFareDialog: true })
      } else if (isCancellationPolicyChange) {
        const status =
          rateCluster!.rates[0].status === "EXPIRATION_DATE_CHANGE" ? "expiration_date_changed" : "refundable_changed"
        this.setState({ openWarningsDialog: true, warningsDialogStatus: status })
      } else if (isSoldOut) {
        this.setState({ notFound: "notFound" })
      }
    }
  }

  createRoomGuests = (rooms: Object[]) => {
    let roomGuests: any[] = []
    for (let index = 0; index < rooms.length; index++) {
      roomGuests.push({ firstName: "", lastName: "", documentType: "", documentNumber: "" })
    }

    return roomGuests
  }

  createBillingInformation = () => {
    const { site } = this.state
    return {
      fiscal_name: "",
      fiscal_id: "",
      fiscal_situation: checkoutConfig.config[site].defaults.fiscalSituation,
      province: "",
      city: "",
      street: "",
      street_number: "",
      floor_number: "",
      building_number: "",
      postal_code: ""
    }
  }

  createContactInformation = () => {
    const { site } = this.state
    return {
      email: "",
      email_confirmation: "",
      telephone_type: checkoutConfig.config[site].defaults.telephones.type,
      telephone_country_code: checkoutConfig.config[site].defaults.telephones.country,
      telephone_area_code: checkoutConfig.config[site].defaults.telephones.area,
      telephone_number: ""
    }
  }

  onChange = target => (field, value) => {
    const data = { ...this.state[target], [field]: value }
    if (data[field + "_error"]) delete data[field + "_error"]
    this.setState({ [target]: data })
  }

  onRoomGuestChange = (i: number, field: string, value: string) => {
    const { roomGuests } = this.state
    const roomGuest = { ...roomGuests[i], [field]: value }
    if (roomGuest[field + "_error"]) delete roomGuest[field + "_error"]
    const state = { roomGuests: [...roomGuests.slice(0, i), roomGuest, ...roomGuests.slice(i + 1, roomGuests.length)] }
    this.setState(state)
  }

  validateContactInfo = (): boolean => {
    const contactInfo = clone(this.state.contactInfo)

    if (!contactInfo) return false

    const { t } = this.props

    let isValid = true
    if (!contactInfo.email) {
      isValid = false
      contactInfo.email_error = t("AccommodationsCheckout.emailError")
    } else {
      if (!validations.validateEmail(contactInfo.email)) {
        isValid = false
        contactInfo.email_error = t("AccommodationsCheckout.emailInvalid")
      } else {
        if (contactInfo.email !== contactInfo.email_confirmation) {
          isValid = false
          contactInfo.email_confirmation_error = t("AccommodationsCheckout.emailConfirmation")
        }
      }
    }

    if (!contactInfo.telephone_country_code) {
      isValid = false
      contactInfo.telephone_country_code_error = t("AccommodationsCheckout.telephoneCountryCode")
    }
    if (!validations.validateNumeric(contactInfo.telephone_country_code)) {
      isValid = false
      contactInfo.telephone_country_code_error = t("AccommodationsCheckout.telephoneInvalid")
    }

    contactInfo.telephone_area_code_error = ""

    if (contactInfo.telephone_type !== "M") {
      if (!contactInfo.telephone_area_code) {
        isValid = false
        contactInfo.telephone_area_code_error = t("AccommodationsCheckout.telephoneAreaCode")
      }
      if (!validations.validateAreaCode(contactInfo.telephone_area_code)) {
        isValid = false
        contactInfo.telephone_area_code_error = t("AccommodationsCheckout.telephoneInvalid")
      }
    }

    if (!contactInfo.telephone_number) {
      isValid = false
      contactInfo.telephone_number_error = t("AccommodationsCheckout.telephoneNumber")
    }
    if (!validations.validateNumeric(contactInfo.telephone_number)) {
      isValid = false
      contactInfo.telephone_number_error = t("AccommodationsCheckout.telephoneInvalid")
    }

    this.setState({ contactInfo })
    return isValid
  }

  validateBillingInfo = (): boolean => {
    const billingInfo = clone(this.state.billingInfo)
    const { t } = this.props
    const { site } = this.state

    const chkConfig = checkoutConfig.config[site]

    let identification = chkConfig.billing.identification ? chkConfig.billing.identification : []
    let addressStreet = chkConfig.billing.address_street ? chkConfig.billing.address_street : []
    let addressExtra = chkConfig.billing.address_extra ? chkConfig.billing.address_extra : []
    let fiscalId = chkConfig.billing.fiscal_id ? chkConfig.billing.fiscal_id : []
    let fiscalSituation = chkConfig.billing.fiscal_situation ? chkConfig.billing.fiscal_situation : []

    let config = identification
      .concat(addressStreet)
      .concat(addressExtra)
      .concat(fiscalId)
      .concat(fiscalSituation)

    let isValid = this.validateFields(config, billingInfo, "AccommodationsCheckout.billingError.")
    if (!validations.validateFiscalID(billingInfo.fiscal_id, billingInfo.fiscal_situation, site)) {
      billingInfo.fiscal_id_error = t("AccommodationsCheckout.billingError.fiscal_id")
      isValid = false
    }

    return isValid
  }

  validateRoomGuests = (): boolean => {
    const roomGuests = clone(this.state.roomGuests)
    const { t } = this.props
    const { site } = this.state
    let valid = true

    for (let roomGuest of roomGuests) {
      if (!roomGuest.firstName) {
        roomGuest.firstName_error = t("AccommodationsCheckout.firstNameError")
        valid = false
      }
      if (!validations.validateAlphabetic(roomGuest.firstName)) {
        roomGuest.firstName_error = t("AccommodationsCheckout.firstNameError")
        valid = false
      }

      if (!roomGuest.lastName) {
        roomGuest.lastName_error = t("AccommodationsCheckout.lastNameError")
        valid = false
      }
      if (!validations.validateAlphabetic(roomGuest.lastName)) {
        roomGuest.lastName_error = t("AccommodationsCheckout.lastNameError")
        valid = false
      }

      if (!roomGuest.documentType) {
        roomGuest.documentType_error = t("AccommodationsCheckout.documentTypeError")
        valid = false
      }
      if (!roomGuest.documentNumber) {
        roomGuest.documentNumber_error = t("AccommodationsCheckout.documentNumberError")
        valid = false
      }
      if (roomGuest.documentType && roomGuest.documentNumber && roomGuest.birthYear) {
        if (
          !validations.validateDocumentNumber(
            site,
            roomGuest.documentNumber,
            roomGuest.documentType,
            roomGuest.birthYear
          )
        ) {
          roomGuest.documentNumber_error = t("AccommodationsCheckout.documentNumberInvalid")
          valid = false
        }
      }
    }
    this.setState({ roomGuests })
    return valid
  }

  validateFields = (cofig: any, billingInfo: any, prefix: string): boolean => {
    const { t } = this.props
    let isValid = true
    if (cofig.length > 0) {
      for (let i in cofig) {
        let name = cofig[i].name
        if (cofig[i].required && !billingInfo[name]) {
          isValid = false
          billingInfo[name + "_error"] = t(prefix + name)
        }
      }
    }
    this.setState({ billingInfo })
    return isValid
  }

  hideTermsAndConditions = () => {
    this.setState({ showTermAndConditions: false })
  }

  showTermAndConditions = () => {
    this.setState({ showTermAndConditions: true })
  }

  validateAndPurchase = async () => {
    const { userId } = this.props
    const { site, agencyId, rateCluster, roomCompanionsMap } = this.state
    const lang = getLocale(this.props.i18n).substring(0, 2)

    if (this.validate()) {
      const req = createAccommodationPurchaseModel(
        this.props.match.params.id,
        this.state.roomGuests,
        null,
        this.state.billingInfo,
        this.state.contactInfo,
        this.state.site,
        this.state.formConfig,
        lang,
        rateCluster!.rooms.map(r => r.id),
        roomCompanionsMap
      )
      this.setState({ isBooking: true })
      try {
        const resProc = await createReservationAsync(req, userId, site, agencyId)
        let interval = setInterval(async () => {
          const reservationProcess = await getReservationProcess(resProc.id, userId, site, agencyId)
          switch (reservationProcess.status) {
            case "OK":
              const { paymentsURL } = this.state
              if (paymentsURL) {
                let url = paymentsURL.replace("{id}", `${reservationProcess.reservation_id}`)
                const { _ga } = qs.parse(this.props.location.search.substr(1))

                if (_ga) {
                  url = url.replace("{_ga}", _ga)
                } else {
                  const ga = ReactGA.ga()
                  ga(function(tracker) {
                    let clientId = tracker.get("clientId")
                    url = url.replace("{_ga}", clientId)
                  })
                }

                window.location.href = url
              } else {
                this.props.history.push(
                  `/checkout/accommodations/reservations/${reservationProcess.reservation_id}/thanks`
                )
              }
              clearInterval(interval)
              break
            case "ERROR":
              console.error(reservationProcess.error_message)
              this.setState({ notFound: "notFound" })
              clearInterval(interval)
              break
            default:
              console.log("Still booking...")
              break
          }
        }, 1000)
      } catch (err) {
        console.error(err)
        this.setState({ notFound: "notFound" })
      }
    }
  }

  handleValidateRoomCompanionsSection = (): boolean => {
    const { t } = this.props
    const { site } = this.state

    const roomCompanionsMapCopy = clone(this.state.roomCompanionsMap)
    const roomCompanionErrorsCopy = clone(this.state.roomCompanionErrors)

    const valid = validateRoomCompanionsSection(
      roomCompanionsMapCopy,
      roomCompanionErrorsCopy,
      this.state.rateCluster,
      site,
      t
    )
    this.setState({ roomCompanionsMap: roomCompanionsMapCopy, roomCompanionErrors: roomCompanionErrorsCopy })

    return valid
  }

  validate = (): boolean => {
    const { formConfig } = this.state
    const validRooms = !formConfig.passengers ? true : this.validateRoomGuests()
    const validShortNames = !formConfig.passengers
      ? true
      : validateNameLength(this.props.t, this.state.rateCluster, this.state.roomGuests)
    const validBillingInfo =
      !formConfig.billingInfo || formConfig.autocompleteBillingInfo ? true : this.validateBillingInfo()
    const validContactInfo = !formConfig.contactInfo ? true : this.validateContactInfo()
    const validRoomCompanionsSection = this.handleValidateRoomCompanionsSection()
    return validRooms && validShortNames && validBillingInfo && validContactInfo && validRoomCompanionsSection
  }

  termAndConditions = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "8px",
    marginBottom: "16px",
    " > p": {
      fontSize: "14px",
      fontWeight: "300",
      lineHeight: "1.14",
      margin: 0,
      marginLeft: "8px",
      color: "#4a4a4a",
      " > button": {
        color: this.props.theme.colors.brand_primary,
        cursor: "pointer",
        fontSize: "inherit",
        background: "none !important",
        border: "none",
        "&:focus": {
          outline: 0
        }
      }
    }
  })

  onChangeTermAndConditions = (event: any) => {
    const { checked } = event.target
    this.setState({ termsAndConditionAccepted: checked })
  }

  goBackToSearch = () => {
    this.props.history.goBack()
  }

  handleOnUpdateRoomGuests = (roomGuests: any): void => {
    this.setState({ roomGuests: roomGuests })
  }

  handleOnUpdateRoomCompanionsMap = (roomCompanionsMap: Record<number, any>): void => {
    this.setState({ roomCompanionsMap: roomCompanionsMap })
  }

  handleAcceptWarningDialog = () => {
    this.setState({ openWarningsDialog: false, warningsDialogStatus: "" })
  }

  handleCancelWarningDialog = () => {
    this.setState({ openWarningsDialog: false, warningsDialogStatus: "" })
    this.goBackToSearch()
  }

  handleAcceptChangedDialog = () => {
    const { openChangeFareDialog } = this.state
    const state = openChangeFareDialog

    this.setState({ openChangeFareDialog: !state })

    if (state) {
      this.goBackToSearch()
    }
  }

  render() {
    const { t, i18n, theme } = this.props
    const {
      billingInfo,
      site,
      geo,
      isBooking,
      contactInfo,
      isFetching,
      rateCluster,
      accommodation,
      roomGuests,
      paymentsURL,
      termsAndConditionAccepted,
      showTermAndConditions,
      termAndConditions,
      notFound,
      roomCompanionsMap,
      roomCompanionErrors,
      openWarningsDialog,
      warningsDialogStatus,
      openChangeFareDialog
    } = this.state
    let chargeDisclamers: any[] = []

    const locale = getLocale(i18n)

    if (isFetching) {
      return <ProductLoader is_mobile={this.isMobile} product_variant="ACCOMMODATIONS_CHECKOUT" variant="WEB" />
    }

    if (notFound !== "" || !rateCluster) {
      return (
        <div className={styles.containerStyle}>
          <div className={styles.mainContainerStyle}>
            <div className={styles.notFoundContainerStyle}>
              <NotFound
                description={t<string>(`AccommodationsCheckout.${notFound}`)}
                onBackToSearch={this.goBackToSearch}
              />
            </div>
          </div>
        </div>
      )
    }

    const checkin = moment(rateCluster.rates[0].checkin)
    const checkout = moment(rateCluster.rates[0].checkout)
    const nights = checkout.diff(checkin, "days")
    const checkinDate = checkin.utc().format("ddd DD MMM YYYY")
    const checkoutDate = checkout.utc().format("ddd DD MMM YYYY")
    const chkConfig = checkoutConfig.config[site]

    chargeDisclamers.push({
      label: t("AccommodationsCheckout.priceRoomLabel"),
      price: `${formatPriceWeb(rateCluster.rates[0].fare.nightly_basis, locale, rateCluster.rates[0].fare.currency)}`
    })

    let childAges: any[] = []
    let adultsQty = 0

    for (let room of rateCluster.rooms) {
      if (room.child_ages) {
        childAges.push(...room.child_ages)
        adultsQty += room.capacity - room.child_ages.length
      } else {
        adultsQty += room.capacity
      }
    }

    const fees =
      rateCluster.rates[0].fare.fees && rateCluster.rates[0].fare.fees.length > 0
        ? rateCluster.rates[0].fare.fees.filter(f => !EXCLUDED_FEE_TYPES.includes(f.type))
        : undefined

    return (
      <>
        <div className={styles.containerStyle}>
          {isBooking && (
            <ProductLoader is_mobile={this.isMobile} product_variant="ACCOMMODATIONS_PAYMENT" variant="WEB" />
          )}

          {!isBooking && (
            <div>
              <p className={styles.titleStyle(theme)}>{t<string>("AccommodationsCheckout.title")}</p>
              <div className={styles.mainContainerStyle}>
                <div className={styles.formContainerStyle}>
                  {this.state.formConfig.passengers && (
                    <FormSection title={t<string>("AccommodationsCheckout.hosts")}>
                      <CommonCheckoutRoomSection
                        roomGuests={roomGuests}
                        roomCapacities={rateCluster.rooms.map(r => r.capacity - 1)}
                        roomChildrenCount={rateCluster.rooms.map(r => r.child_ages?.length || 0)}
                        roomCompanionsMap={roomCompanionsMap}
                        roomCompanionErrors={roomCompanionErrors}
                        chkConfig={chkConfig}
                        onUpdateRoomGuests={this.handleOnUpdateRoomGuests}
                        onUpdateRoomCompanionsMap={this.handleOnUpdateRoomCompanionsMap}
                      />
                    </FormSection>
                  )}
                  {this.state.formConfig.billingInfo && (
                    <FormSection title={t<string>("AccommodationsCheckout.billing")}>
                      {this.state.formConfig.autocompleteBillingInfo && (
                        <Message open variant="fixed" action="info" message={t("FormConfig.autocompleteBillingInfo")} />
                      )}
                      <BillingContainer
                        onChange={this.onChange("billingInfo")}
                        billingInfo={billingInfo}
                        checkoutConfig={chkConfig}
                        geo={geo}
                      />
                    </FormSection>
                  )}
                  {this.state.formConfig.contactInfo && (
                    <FormSection title={t<string>("AccommodationsCheckout.contact")}>
                      <ContactContainer onChange={this.onChange("contactInfo")} contact={contactInfo} />
                    </FormSection>
                  )}
                  <div className={`${this.termAndConditions}`}>
                    <Checkbox
                      classes={{
                        root: `${styles.checkboxStyle}`,
                        checked: `${styles.checkboxCheckedStyle(theme)}`
                      }}
                      checked={termsAndConditionAccepted}
                      onClick={this.onChangeTermAndConditions}
                    />
                    <p>
                      {t<string>("AccommodationsCheckout.termAndConditions")}{" "}
                      <button onClick={this.showTermAndConditions}>
                        {t<string>("AccommodationsCheckout.termAndConditionsLink")}
                      </button>
                    </p>
                  </div>
                  <Button
                    variant={"contained"}
                    classes={{ root: `${styles.buttonStyle(this.props.theme)}` }}
                    onClick={this.validateAndPurchase}
                    disabled={isBooking || !termsAndConditionAccepted}
                  >
                    {paymentsURL
                      ? t<string>("AccommodationsCheckout.buyButton")
                      : t<string>("AccommodationsCheckout.reserveButton")}
                  </Button>
                </div>
                <div className={styles.detailContainerStyle}>
                  <div>
                    <PriceboxBreakdownCheckoutWeb
                      accommodationFare={rateCluster.rates[0].fare}
                      rooms={rateCluster.rooms.length}
                      guests={adultsQty}
                    />
                  </div>
                  {fees && fees.length > 0 && (
                    <div style={{ marginTop: "16px" }}>
                      <Fees fees={fees} site={site} />
                    </div>
                  )}
                  <div className={styles.hotelDetailContainer}>
                    <AccommodationDetail
                      childAges={childAges}
                      adultsQty={adultsQty}
                      refundable={rateCluster.rates[0].refundable}
                      checkin={checkinDate}
                      checkout={checkoutDate}
                      nights={nights}
                      rooms={rateCluster.rooms}
                      cancelPenalties={rateCluster.rates[0].cancel_penalties}
                      expirationDate={rateCluster.rates[0].expiration_date}
                      customExpirationDate={rateCluster.rates[0].custom_expiration_date}
                      accommodation={accommodation}
                      mealPlan={t(`Accommodation.mealPlan.${rateCluster.rates[0].meal_plan}`)}
                      comments={rateCluster.rates[0].comments}
                      site={site}
                      handleOpenChangedFareDialog={this.handleAcceptChangedDialog}
                      openChangedFareDialog={openChangeFareDialog}
                      rateCluster={rateCluster}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {!isBooking && (
          <TermsAndConditions
            showTermAndConditions={showTermAndConditions}
            hideTermsAndConditions={this.hideTermsAndConditions}
            termAndConditions={termAndConditions}
            showProviderTerms={true}
          />
        )}
        <WarningsDialog
          open={openWarningsDialog}
          hotelName={rateCluster.rooms[0].name}
          status={warningsDialogStatus}
          onCancel={this.handleCancelWarningDialog}
          onAccept={this.handleAcceptWarningDialog}
        />
      </>
    )
  }
}

export default withConfig(theme(withAuthUser(withTranslation(I18N_NS)(AccommodationsCheckout) as any)))
