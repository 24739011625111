import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation, useParams } from "react-router-dom"

import moment from "moment"
import ReactGA from "react-ga"
import qs from "qs"

import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import PriceBreakdownAgency from "@basset-la/components-insurances/dist/components/PriceBreakdownAgency"
import CheckoutForm from "@basset-la/components-insurances/dist/components/CheckoutForm"
import Message from "@basset-la/components-commons/dist/components/Message"
import ProductLoader from "@basset-la/components-commons/dist/components/ProductLoader"
import Text from "@basset-la/components-commons/dist/components/Text"
import BillingContainer from "@basset-la/commons-frontend/dist/components/checkout/billing/BillingContainer"
import { CheckoutPassenger, Insurance } from "@basset-la/components-insurances/dist/models"
import { validations } from "@basset-la/commons-frontend"
import { getLocale } from "@basset-la/components-products/dist/utils/helpers"
import { useAuthUser, useConfig } from "@basset-la/components-commons"
import { useTheme } from "@basset-la/themed-components"
import EmergencyContactForm from "@basset-la/components-insurances/dist/components/EmergencyContactForm"

import {
  parseCheckoutPassengers,
  extraCoveragesFromUrl,
  createInsurancePurchaseModel
} from "../../../utils/insurancesUtils"

import styles from "./InsurancesCheckout.styles"

import FormSection from "../../common/FormSection"
import ModalDialog from "../../common/ModalDialog"
import NotFound from "../../NotFound"
import TermsAndConditions from "../../TermsAndConditions"
import ContactContainer from "../../contact/ContactContainer"

import { getInsuranceDetail } from "../../../api/insurances"
import { getCountries, getProviderConfig, getStatesByCountryCode } from "../../../api/common"
import { createReservationAsync, getReservationProcess } from "../../../api/reservations"

import { I18N_NS } from "../../../utils/constants"
import { FormConfig, getFormConfig } from "../../../config/formConfig"
import * as checkoutConfig from "../../config/checkoutConfig.json"

import { BillingInformation, ContactInformation, Geo } from "../../types"
import { CheckoutEmergencyContact, InsurancesProviderConfig } from "../../../utils/common"
import InsuranceClusterCheckout from "@basset-la/components-insurances/dist/components/InsuranceClusterCheckout"
import { useOpenInsuranceDetails } from "@basset-la/components-insurances/dist/hooks"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"

interface PathParams {
  id: string
}

export interface SearchParams {
  covered?: string[]
}

const validAges = { min: 1, max: 120 }

const dateFormat = "YYYY-MM-DD"

const InsurancesCheckout: React.FC<{}> = () => {
  const [product, setProduct] = useState<Insurance>()
  const [passengersForm, setPassengersForm] = useState<Array<CheckoutPassenger>>([])
  const [contactInfo, setContactInfo] = useState<ContactInformation>()
  const [billingInfo, setBillingInfo] = useState<BillingInformation>()
  const [geo, setGeo] = useState<Geo>()
  const [formConfig, setFormConfig] = useState<FormConfig>(getFormConfig())
  const [termAndConditions, setTermAndConditions] = useState<any>()
  const [emergencyContact, setEmergencyContact] = useState<CheckoutEmergencyContact>({
    name: "",
    lastName: "",
    phone: "",
    errors: {}
  })
  const [providerConfig, setProviderConfig] = useState<InsurancesProviderConfig>({
    id: "",
    emergency_contact_required: false
  })

  const [isFetching, setIsFetching] = useState(false)
  const [isBooking, setIsBooking] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [hasExtras, setHasExtras] = useState(false)
  const [showTermAndConditions, setShowTermAndConditions] = useState<boolean>(false)
  const [openChangeCancelPolicies, setOpenChangeCancelPolicies] = useState(false)
  const [termsAndConditionAccepted, setTermsAndConditionAccepted] = useState<boolean>(false)

  const {
    loadingDetails,
    onGetClusterDetail,
    onCloseDetailsWrapper: onGetOnCloseDetailsWrapper,
    onOpenDetails,
    openDetails
  } = useOpenInsuranceDetails((id, site, channel, language) =>
    getInsuranceDetail(id, site, channel, language, config.agency_id)
  )

  // config hooks
  const { id } = useParams<PathParams>()

  const { search } = useLocation()

  const history = useHistory()

  const { config } = useConfig()

  const theme = useTheme()

  const isMobile = useMediaQuery("(max-width: 1024px)")

  const { i18n, t } = useTranslation(I18N_NS)

  const { userId } = useAuthUser()

  // componentDidMount
  useEffect(() => {
    const init = async () => {
      setIsFetching(true)

      const queryParams = qs.parse(search, { ignoreQueryPrefix: true })

      setHasExtras(queryParams["covered"] !== undefined)

      try {
        const [insuranceResponse, countries, states] = await Promise.all([
          getInsuranceDetail(id, config.country, "WEB", config.language, config.agency_id),
          getCountries(config.agency_id, config.language),
          getStatesByCountryCode(config.country, config.agency_id, config.language)
        ])

        const pc = await getProviderConfig(insuranceResponse.provider_configuration_id, config.agency_id)

        const insuranceWithCovered = extraCoveragesFromUrl(search, insuranceResponse)

        setProduct(insuranceWithCovered)
        setPassengersForm(parseCheckoutPassengers(insuranceWithCovered))
        setGeo({ countries, states })
        setBillingInfo(createBillingInformation(config.country))
        setContactInfo(createContactInformation(config.country))
        setFormConfig(getFormConfig(config.checkout_form_config))
        setTermAndConditions(config.brand.terms_and_conditions)
        setProviderConfig(pc)
      } catch (error) {
        console.log(error)

        setNotFound(true)
      }

      setIsFetching(false)
    }

    if (!id) {
      setNotFound(true)

      return
    }

    init()
  }, [])

  // componentDidUpdate
  useEffect(() => {
    document.title = t<string>("InsurancesCheckout.documentTitle")
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [t])

  // handlers
  const onChangePassenger = (index: number, passenger: CheckoutPassenger) => {
    const newPassengersForm = [...passengersForm]
    newPassengersForm[index] = { ...passenger, errors: {} }
    setPassengersForm(newPassengersForm)
  }

  const handleChangeEmergencyContact = (c: CheckoutEmergencyContact) => {
    setEmergencyContact({
      ...c,
      errors: {}
    })
  }

  const onChange = (target: "billingInfo" | "contactInfo") => (field: string, value: string) => {
    switch (target) {
      case "billingInfo":
        const billing = { ...billingInfo, [field]: value }

        if (billing[field + "_error"]) delete billing[field + "_error"]

        setBillingInfo(billing)

        break

      case "contactInfo":
        const contact = { ...contactInfo, [field]: value }

        if (contact[field + "_error"]) delete contact[field + "_error"]

        setContactInfo(contact)

        break
    }
  }

  const onChangeTermAndConditions = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    setTermsAndConditionAccepted(checked)
  }

  const handleShowTermAndConditions = () => {
    setShowTermAndConditions(true)
  }

  const hideTermsAndConditions = () => {
    setShowTermAndConditions(false)
  }

  const handleRedirectToExternalPayment = (id: string) => {
    if (!config?.checkout_url || config.checkout_url === "/checkout/{product}/{id}") {
      return
    }

    let replacedPath = config!.checkout_url.replace("{id}", id).replace("{product}", "insurances")

    const { _ga } = qs.parse(window.location.search.substr(1))

    if (_ga) {
      replacedPath = replacedPath.replace("{_ga}", _ga)
    } else {
      const ga = ReactGA.ga()
      ga(function(tracker) {
        const clientId = tracker.get("clientId")
        replacedPath = replacedPath.replace("{_ga}", clientId)
      })
    }

    window.location.href = replacedPath
  }

  const validateFields = (config: any, info: any, prefix: string): boolean => {
    let isValid = true
    if (config.length > 0) {
      for (let i in config) {
        let name = config[i].name
        let regexValid = true

        if (config[i].regex) {
          let regex = new RegExp(config[i].regex, "g")
          regexValid = regex.test(info[name])
        }

        if (config[i].required && (!info[name] || !regexValid)) {
          isValid = false
          info[name + "_error"] = t(prefix + name)
        }
      }
    }

    return isValid
  }

  const validateBillingInfo = (): boolean => {
    if (!billingInfo) return false

    const billing = { ...billingInfo }
    const chkConfig = checkoutConfig.config[config.country]
    const identification = chkConfig.billing.identification ? chkConfig.billing.identification : []
    const addressStreet = chkConfig.billing.address_street ? chkConfig.billing.address_street : []
    const addressExtra = chkConfig.billing.address_extra ? chkConfig.billing.address_extra : []
    const fiscalId = chkConfig.billing.fiscal_id ? chkConfig.billing.fiscal_id : []
    const fiscalSituation = chkConfig.billing.fiscal_situation ? chkConfig.billing.fiscal_situation : []

    const configuration = identification
      .concat(addressStreet)
      .concat(addressExtra)
      .concat(fiscalId)
      .concat(fiscalSituation)

    let isValid = validateFields(configuration, billing, "InsurancesCheckout.billingError.")

    if (!validations.validateFiscalID(billing.fiscal_id || "", billing.fiscal_situation || "", config.country)) {
      billing.fiscal_id_error = t<string>("InsurancesCheckout.billingError.fiscal_id")
      isValid = false
    }

    if (!isValid) {
      setBillingInfo(billing)
    }

    return isValid
  }

  const validateContactInfo = (): boolean => {
    if (!contactInfo) return false

    let isValid = true

    const contact = { ...contactInfo }

    if (!contact.email) {
      isValid = false
      contact.email_error = t<string>("InsurancesCheckout.contactInfoError.emailError")
    } else {
      if (!validations.validateEmail(contact.email)) {
        isValid = false
        contact.email_error = t<string>("InsurancesCheckout.contactInfoError.emailInvalid")
      } else {
        if (contact.email !== contact.email_confirmation) {
          isValid = false
          contact.email_confirmation_error = t<string>("InsurancesCheckout.contactInfoError.emailConfirmation")
        }
      }
    }

    if (!contact.telephone_country_code) {
      isValid = false
      contact.telephone_country_code_error = t<string>("InsurancesCheckout.contactInfoError.telephoneCountryCode")
    }

    if (!validations.validateNumeric(contact.telephone_country_code || "")) {
      isValid = false
      contact.telephone_number_error = t<string>("InsurancesCheckout.contactInfoError.telephoneInvalid")
    }

    contact.telephone_area_code_error = ""

    if (contact.telephone_type !== "M") {
      if (!contact.telephone_area_code) {
        isValid = false
        contact.telephone_area_code_error = t<string>("InsurancesCheckout.contactInfoError.telephoneAreaCode")
      }

      if (!validations.validateAreaCode(contact.telephone_area_code || "")) {
        isValid = false
        contact.telephone_number_error = t<string>("InsurancesCheckout.contactInfoError.telephoneInvalid")
      }
    }

    if (!contact.telephone_number) {
      isValid = false
      contact.telephone_number_error = t<string>("InsurancesCheckout.contactInfoError.telephoneNumber")
    }

    if (!validations.validateNumeric(contact.telephone_number || "")) {
      isValid = false
      contact.telephone_number_error = t<string>("InsurancesCheckout.contactInfoError.telephoneInvalid")
    }

    if (!isValid) {
      setContactInfo(contact)
    }

    return isValid
  }

  const validatePassengers = (): boolean => {
    const copy = [...passengersForm]

    let isValid = true

    copy.forEach((passenger, index) => {
      let nameError = ""

      let lastNameError = ""

      let birthdayError = ""

      let documentError = ""

      let validThruError = ""

      const birthDate = moment(`${passenger.birthYear}-${passenger.birthMonth}-${passenger.birthDay}`, dateFormat)

      if (!passenger.name) {
        isValid = false
        nameError = t<string>("InsurancesCheckout.passengerError.firstName")
      }
      if (!passenger.lastName) {
        isValid = false
        lastNameError = t<string>("InsurancesCheckout.passengerError.lastName")
      }
      if (!passenger.birthDay) {
        isValid = false
        birthdayError = t<string>("InsurancesCheckout.passengerError.birthDay")
      }
      if (!passenger.birthMonth) {
        isValid = false
        birthdayError = t<string>("InsurancesCheckout.passengerError.birthMonth")
      }
      if (!passenger.birthYear) {
        isValid = false
        birthdayError = t<string>("InsurancesCheckout.passengerError.birthYear")
      }

      if (birthDate.isValid()) {
        const ratePassengerAge = product?.rate.passengers[index].age

        const prevAge =
          typeof ratePassengerAge === "string" ? moment().diff(ratePassengerAge, "years") : ratePassengerAge

        const currentAge = moment().diff(birthDate, "years")

        if (currentAge !== prevAge) {
          isValid = false
          birthdayError = t<string>("InsurancesCheckout.passengerError.ageDistmatch")
        }
      }

      if (passenger.documentNumber) {
        if (passenger.validThru) {
          const validThru = moment(passenger.validThru, dateFormat)

          if (!validThru.isValid() || validThru.isBefore(moment())) {
            isValid = false
            validThruError = t<string>("InsurancesCheckout.passengerError.invalidValidThru")
          }
        }
      } else {
        isValid = false
        documentError = t<string>("InsurancesCheckout.passengerError.documentNumber")
      }

      if (!isValid) {
        copy[index] = {
          ...passenger,
          errors: {
            nameError: nameError || undefined,
            lastNameError: lastNameError || undefined,
            birthdayError: birthdayError ? birthdayError : undefined,
            documentError: documentError ? documentError : undefined,
            validThruError: validThruError ? validThruError : undefined
          }
        }
      }
    })

    setPassengersForm(copy)

    return isValid
  }

  const validateEmergencyContact = (): boolean => {
    let isValid = true

    const copy: CheckoutEmergencyContact = {
      ...emergencyContact,
      errors: {}
    }

    if (copy.name === "") {
      isValid = false
      copy.errors.nameError = t<string>("InsurancesCheckout.emergencyContact.firstName")
    }
    if (copy.lastName === "") {
      isValid = false
      copy.errors.lastNameError = t<string>("InsurancesCheckout.emergencyContact.lastName")
    }
    if (copy.phone === "") {
      isValid = false
      copy.errors.phoneError = t<string>("InsurancesCheckout.emergencyContact.phone")
    }

    setEmergencyContact({
      ...copy,
      errors: { ...copy.errors }
    })

    return isValid
  }

  const validate = (formConfig: FormConfig): boolean => {
    const validPassengers = !formConfig.passengers ? true : validatePassengers()
    const validEmergencyContact = !providerConfig.emergency_contact_required ? true : validateEmergencyContact()
    const validBillingInfo =
      !formConfig.billingInfo || formConfig.autocompleteBillingInfo ? true : validateBillingInfo()
    const validContactInfo = !formConfig.contactInfo ? true : validateContactInfo()

    return validPassengers && validEmergencyContact && validBillingInfo && validContactInfo
  }

  const validateAndPurchase = async () => {
    const lang = getLocale(i18n).substring(0, 2)

    const { country: site, agency_id: agencyId } = config

    if (!validate(formConfig)) return

    try {
      setIsBooking(true)

      const req = createInsurancePurchaseModel(
        passengersForm,
        emergencyContact,
        billingInfo,
        contactInfo,
        formConfig,
        site,
        lang,
        product!
      )

      const resProc = await createReservationAsync(req, userId, site, agencyId)

      const interval = setInterval(async () => {
        const reservationProcess = await getReservationProcess(resProc.id, userId, site, agencyId)

        switch (reservationProcess.status) {
          case "OK":
            handleRedirectToExternalPayment(reservationProcess.reservation_id)

            history.push(`/checkout/insurances/reservations/${reservationProcess.reservation_id}/thanks`)

            clearInterval(interval)
            break
          case "ERROR":
            console.error(reservationProcess.error_message)

            setIsBooking(false)

            setNotFound(true)

            clearInterval(interval)

            break
          default:
            console.log("Still booking...")
            break
        }
      }, 1000)
    } catch (err) {
      console.error(err)

      setIsBooking(false)

      setNotFound(true)
    }
  }

  const goBack = () => {
    if (hasExtras) {
      history.goBack()

      return
    }

    window.history.go(-2)
  }

  const handleGetInsuranceDetail = async (): Promise<void> => {
    if (!product) {
      return
    }

    try {
      if (product.coverages.length) {
        onOpenDetails(product.id)
        return
      }

      const detail = await onGetClusterDetail(product.id, config.country, "WEB", config.language)

      setProduct(detail)
    } catch (error) {
      console.log(error)
    }
  }

  if (isFetching) {
    return <ProductLoader is_mobile={isMobile} variant="WEB" product_variant="INSURANCES_CHECKOUT" />
  }

  if (isBooking) {
    return <ProductLoader is_mobile={isMobile} variant="WEB" product_variant="INSURANCES_PAYMENT" />
  }

  if (notFound) {
    return (
      <div className={`${styles.background(theme)}`}>
        <div className={`${styles.checkout}`}>
          <div className={`${styles.notFoundContainer}`}>
            <NotFound description={t<string>("InsurancesCheckout.notFound")} onBackToSearch={goBack} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Button className={styles.goBack(theme)} variant="text" startIcon={<ArrowBackIosIcon />} onClick={goBack}>
          <Text className={styles.goBack(theme)}>{"Volver"}</Text>
        </Button>
        <Text size={24} className={styles.title(theme)}>
          {t<string>("InsurancesCheckout.description")}
        </Text>
      </div>

      <div className={styles.container}>
        {product && (
          <div className={styles.rightContainer}>
            <PriceBreakdownAgency
              variant="web-full"
              rate={product.rate}
              fromDate={moment(product.dates.from, dateFormat).format("DD/MM/YYYY")}
              provider={product.provider}
            />
            <InsuranceClusterCheckout
              insurance={product}
              variant="WEB"
              onOpenChangeAndCancelPolicies={
                product.terms.cancellation_policy ? () => setOpenChangeCancelPolicies(true) : undefined
              }
              loadingDetails={loadingDetails[product.id]}
              onCloseDetails={onGetOnCloseDetailsWrapper(product.id)}
              onClusterDetail={handleGetInsuranceDetail}
              openDetails={openDetails[product.id]}
              isCollapsible={isMobile}
            />
          </div>
        )}

        <div className={styles.itemsContainer}>
          {product &&
            passengersForm.map((item, index) => {
              const passenger = product?.rate.passengers[index]

              const age = typeof passenger.age === "string" ? moment().diff(passenger.age, "years") : passenger.age

              return (
                <FormSection key={`form-section-${index}`}>
                  <CheckoutForm
                    title={t<string>("InsurancesCheckout.passengerTitle", {
                      index: index + 1,
                      count: age
                    })}
                    passenger={item}
                    minAge={validAges.min}
                    maxAge={validAges.max}
                    onChange={p => onChangePassenger(index, p)}
                  />
                </FormSection>
              )
            })}

          {formConfig.billingInfo && billingInfo && (
            <FormSection title={t<string>("InsurancesCheckout.billingTitle")}>
              {formConfig.autocompleteBillingInfo && (
                <Message open variant="fixed" action="info" message={t("FormConfig.autocompleteBillingInfo")} />
              )}
              <BillingContainer
                onChange={onChange("billingInfo")}
                billingInfo={billingInfo}
                checkoutConfig={checkoutConfig.config[config.country]}
                geo={geo}
              />
            </FormSection>
          )}

          {providerConfig.emergency_contact_required && (
            <FormSection>
              <EmergencyContactForm contact={emergencyContact} onChange={handleChangeEmergencyContact} />
            </FormSection>
          )}

          {formConfig.contactInfo && contactInfo && (
            <div className={styles.contactInfoContainer}>
              <span className={styles.voucherBeneficiary(theme)}>
                {t<string>("InsurancesCheckout.whoReceivesVoucher")}
              </span>
              <FormSection title={t<string>("InsurancesCheckout.contactTitle")}>
                <ContactContainer onChange={onChange("contactInfo")} contact={contactInfo} />
              </FormSection>
            </div>
          )}

          <div className={`${styles.termAndConditions(theme)}`}>
            <Checkbox
              classes={{
                root: `${styles.checkbox}`,
                checked: `${styles.checkboxChecked(theme)}`
              }}
              checked={termsAndConditionAccepted}
              onChange={onChangeTermAndConditions}
            />
            <p>
              {t<string>("InsurancesCheckout.termAndConditionsAccept")}{" "}
              <button onClick={handleShowTermAndConditions}>
                {t<string>("InsurancesCheckout.termAndConditionsLink")}
              </button>
            </p>
          </div>

          <Button
            id="buy-button"
            variant={"contained"}
            classes={{ root: `${styles.button(theme)}` }}
            disabled={isBooking || !termsAndConditionAccepted}
            onClick={validateAndPurchase}
          >
            {t<string>("InsurancesCheckout.buyButton")}
          </Button>
        </div>
      </div>

      <ModalDialog open={openChangeCancelPolicies} onClose={() => setOpenChangeCancelPolicies(false)}>
        <span>{product?.terms.cancellation_policy}</span>
      </ModalDialog>

      <TermsAndConditions
        showTermAndConditions={showTermAndConditions}
        hideTermsAndConditions={hideTermsAndConditions}
        termAndConditions={termAndConditions}
        showProviderTerms={false}
      />
    </div>
  )
}

const createBillingInformation = (countryCode: string) => {
  return {
    fiscal_name: "",
    fiscal_id: "",
    fiscal_situation: checkoutConfig.config[countryCode].defaults.fiscalSituation,
    province: "",
    city: "",
    street: "",
    street_number: "",
    floor_number: "",
    building_number: "",
    postal_code: ""
  }
}

const createContactInformation = (countryCode: string) => {
  return {
    email: "",
    email_confirmation: "",
    telephone_type: checkoutConfig.config[countryCode].defaults.telephones.type,
    telephone_country_code: checkoutConfig.config[countryCode].defaults.telephones.country,
    telephone_area_code: checkoutConfig.config[countryCode].defaults.telephones.area,
    telephone_number: ""
  }
}

export default InsurancesCheckout
