import React, { useEffect, useState } from "react"
import { getReservation } from "../../../api/reservations"
import ThanksTotal from "../../thanks/ThanksTotal"
import ReservationConfirmationText from "../../thanks/ReservationConfirmationText"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../../utils/constants"
import Check from "../../../icons/Check"
import { Rate, CarInfo, PlanCoverage, CarType, PlanType, CategoryType } from "@basset-la/components-cars/dist/models"
import { useRouteMatch } from "react-router-dom"
import styles from "./CarsThanks.styles"
import { formatPriceWeb, getLocale } from "@basset-la/components-products/dist/utils/helpers"
import { CarReservation, getReservationProduct, ReservationProduct } from "../../../api/cars"
import CarFeatures from "@basset-la/components-cars/dist/components/CarFeatures"
import CarPickupDropoff from "@basset-la/components-cars/dist/components/CarPickupDropoff"
import { useTheme } from "@basset-la/themed-components"
import ModalDialog from "../ModalDialog/ModalDialog"
import ThanksProductReservationDetail from "../../thanks/ThanksProductReservationDetail"
import { useAuthUser, useConfig } from "@basset-la/components-commons"
import AppLoader from "@basset-la/components-commons/dist/components/AppLoader"
import FlightDetailInfo from "@basset-la/components-flights/dist/components/FlightDetailInfo"
import { CarRateMap } from "@basset-la/components-cars"

interface MatchProps {
  id: string
}

const CarsThanks: React.FC = () => {
  const theme = useTheme()
  const match = useRouteMatch<MatchProps>()
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)

  const { config } = useConfig()
  const { userId } = useAuthUser()

  const [reservation, setReservation] = useState<any | null>(null)
  const [car, setCar] = useState<CarReservation | null>(null)
  const [termsAndConditions, setTermAndConditions] = useState("")
  const [openTerms, setOpenTerms] = useState(false)
  const [providerConfirmationNumber, setProviderConfirmationNumber] = useState("")
  const [flightInfo, setFlightInfo] = useState<ReservationProduct["flight_info"] | undefined>(undefined)
  const [openPickupMap, setOpenPickupMap] = useState(false)
  const [openDropoffMap, setOpenDropoffMap] = useState(false)

  useEffect(() => {
    const retrieveReservation = async () => {
      try {
        const res = await getReservation(match.params.id, userId, config.country, config.agency_id)
        const product = res.products[0]
        const resProduct = await getReservationProduct(config, product.reservation_id)

        setReservation(res)
        setCar(resProduct.car)
        setTermAndConditions(resProduct.terms_and_conditions)
        setProviderConfirmationNumber(resProduct.provider_confirmation_number)
        setFlightInfo(resProduct.flight_info)
      } catch (e) {
        console.error(e)
      }
    }

    if (!reservation && !car) {
      retrieveReservation()
    }
  }, [car, reservation, i18n.language, match.params.id])

  const handleOpenTermsAndConditions = () => {
    setOpenTerms(true)
  }

  const handleCloseTermsAndConditions = () => {
    setOpenTerms(false)
  }

  const handleShowMap = (type: "pickup" | "dropoff") => {
    switch (type) {
      case "pickup":
        setOpenPickupMap(!openPickupMap)
        break
      case "dropoff":
        setOpenDropoffMap(!openDropoffMap)
        break
    }
  }

  if (!reservation || !car) {
    return <AppLoader variant="CARS" />
  }

  const carInfo: CarInfo = {
    air_conditioning: car.air_conditioning,
    automatic_transmission: car.automatic_transmission,
    baggage_quantity: car.baggage_quantity,
    unlimited_mileage: car.unlimited_mileage,
    passenger_quantity: car.passenger_quantity,
    category: car.category as CategoryType,
    doors: car.doors,
    model: car.model,
    image: car.image
  }

  const rate: Rate = {
    id: car.id,
    type: car.type as CarType,
    provider: car.provider,
    pickup: car.pickup,
    dropoff: car.dropoff,
    equipments: car.equipments,
    fare: car.fare,
    plan: car.plan as PlanType,
    coverages: {
      basic_plan: car.plan === "BASIC" ? { coverages: car.coverages, total: 0 } : ({} as PlanCoverage),
      premium_plan: car.plan === "PREMIUM" ? { coverages: car.coverages, total: 0 } : ({} as PlanCoverage)
    },
    rate: { name: "", code: "" }
  }

  return (
    <>
      <ModalDialog open={openTerms} title={t("CarsThanks.termsTitle")} onClose={handleCloseTermsAndConditions}>
        <span dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
      </ModalDialog>

      <div className={styles.container}>
        <div className={styles.title}>
          <div className={styles.checkIcon}>
            <Check />
          </div>
          <p className={styles.titleText}>{t<string>("CarsThanks.title")}</p>
        </div>
        <div className={styles.content}>
          <div className={styles.infoContainer}>
            <ThanksTotal
              currency={car.fare.currency}
              total={formatPriceWeb(car.fare.total, locale, car.fare.currency, {
                hideCurrency: true
              })}
            />
            <ReservationConfirmationText reservationID={reservation.id} email={reservation.contact?.email || ""} />
            <ThanksProductReservationDetail
              title={t<string>("CarsThanks.pnr")}
              code={reservation.products[0].provider_reservation_id}
            />
            <ThanksProductReservationDetail
              title={t("CarsThanks.providerConfirmationNumber")}
              code={providerConfirmationNumber}
            />
          </div>
          <div className={styles.card(theme)}>
            <CarFeatures
              variant="full_checkout"
              isMobile={false}
              cluster={carInfo}
              selectedRate={rate}
              onOpenTermsAndConditions={handleOpenTermsAndConditions}
            />
          </div>
          <div className={styles.infoContainer}>
            <div className={styles.card(theme, true)}>
              <div className={styles.cardItem}>
                <CarPickupDropoff
                  type="pickup"
                  data={car.pickup}
                  showDate={true}
                  showTitle={true}
                  showSameLocationAddress={false}
                  embeddedMap={
                    openPickupMap ? (
                      <CarRateMap
                        variant={"pickup"}
                        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""}
                        rate={
                          {
                            pickup: car.pickup,
                            dropoff: car.dropoff
                          } as Rate
                        }
                      />
                    ) : (
                      undefined
                    )
                  }
                  onOpenMap={() => handleShowMap("pickup")}
                />
              </div>
              <div className={styles.cardItem}>
                <CarPickupDropoff
                  type="dropoff"
                  data={car.dropoff}
                  showDate={true}
                  showTitle={true}
                  showSameLocationAddress={true}
                  embeddedMap={
                    openDropoffMap ? (
                      <CarRateMap
                        variant={"dropoff"}
                        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""}
                        rate={
                          {
                            pickup: car.pickup,
                            dropoff: car.dropoff
                          } as Rate
                        }
                      />
                    ) : (
                      undefined
                    )
                  }
                  onOpenMap={() => handleShowMap("dropoff")}
                />
              </div>
            </div>
          </div>
          {flightInfo && (
            <div className={styles.infoContainer}>
              <div className={styles.card(theme, true)}>
                <FlightDetailInfo flight_info={flightInfo}></FlightDetailInfo>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default CarsThanks
