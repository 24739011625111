import React from "react"
import { InsurancesThanksInfo } from "../insurances/InsurancesThanks/InsurancesThanks"
import InsuranceClusterCheckout from "@basset-la/components-insurances/dist/components/InsuranceClusterCheckout"
import styles from "./CrossSellingThanks.styles"
import { useTheme } from "@basset-la/themed-components"
import { getInsuranceDetail } from "../../api/insurances"
import { useOpenInsuranceDetails } from "@basset-la/components-insurances/dist/hooks"
import { useConfig } from "@basset-la/components-commons"
import { useMediaQuery } from "@material-ui/core"

export interface Props {
  products: any[]
}

const CrossSellingThanks: React.FC<Props> = ({ products }) => {
  const theme = useTheme()

  const { config } = useConfig()
  const isMobile = useMediaQuery("(max-width: 1024px)")

  const {
    loadingDetails,
    openDetails,
    onCloseDetailsWrapper
  } = useOpenInsuranceDetails((id, site, channel, language) =>
    getInsuranceDetail(id, site, channel, language, config.agency_id)
  )

  return (
    <>
      {products.map(p => {
        switch (p.type) {
          case "TRAVEL_INSURANCE":
            const product = p as InsurancesThanksInfo
            return (
              <div className={styles.card(theme)}>
                <InsuranceClusterCheckout
                  insurance={product.insurance}
                  variant="WEB"
                  fullWidth
                  loadingDetails={loadingDetails[product.insurance.id]}
                  onCloseDetails={onCloseDetailsWrapper(product.insurance.id)}
                  openDetails={openDetails[product.insurance.id]}
                  isCollapsible={isMobile}
                />
              </div>
            )
          default:
            return <></>
        }
      })}
    </>
  )
}

export default CrossSellingThanks
