import React, { useCallback, useEffect, useState } from "react"

import clone from "lodash/clone"
import moment from "moment"
import qs from "qs"
import ReactGA from "react-ga"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation, useRouteMatch } from "react-router-dom"

import { validations } from "@basset-la/commons-frontend"
import BillingContainer from "@basset-la/commons-frontend/dist/components/checkout/billing/BillingContainer"
import PassengersContainer from "@basset-la/commons-frontend/dist/components/checkout/passengers/PassengersContainer"
import { validatePassengers as passengersValidations } from "@basset-la/commons-frontend/dist/components/checkout/utils/passengersValidations"
import { FlightsCheckoutPassenger } from "@basset-la/commons-frontend/dist/components/types"
import HotelBookingSummary from "@basset-la/components-accommodations/dist/components/HotelBookingSummary"
import PriceboxBreakdownCheckoutWeb from "@basset-la/components-combined/dist/components/PriceboxBreakdownCheckoutWeb"
import Stepper from "@basset-la/components-combined/dist/components/Stepper"
import { StepType } from "@basset-la/components-combined/dist/models"
import ProductLoader from "@basset-la/components-commons/dist/components/ProductLoader"
import FlightChangedFareDialog from "@basset-la/components-flights/dist/components/ChangedFareDialog"
import HotelChangedFareDialog from "@basset-la/components-accommodations/dist/components/ChangedFareDialog"
import BaggageAllowance from "@basset-la/components-flights/dist/components/CheckoutBaggageAllowance"
import FlightCluster from "@basset-la/components-flights/dist/components/Cluster"
import { ChangeFareCluster, Cluster, Cluster as FlightClusterModel } from "@basset-la/components-flights/dist/model"
import { getLocale } from "@basset-la/components-products/dist/utils/helpers"
import { useTheme } from "@basset-la/themed-components"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import CancelPenaltiesAlert from "@basset-la/components-accommodations/dist/components/CancelPenalties"

import { getAccommodation, getRate } from "../../../api/accommodations"
import { getCart } from "../../../api/combined"
import { getCountries, getStatesByCountryCode } from "../../../api/common"
import { getCluster } from "../../../api/flights"
import { createReservationAsync, getReservationProcess } from "../../../api/reservations"
import { FormConfig, getFormConfig } from "../../../config/formConfig"
import { createCombinedPurchaseModel } from "../../../utils/combinedUtils"
import { I18N_NS } from "../../../utils/constants"
import { isValidFare, validateFieldAirlines } from "../../../utils/flightsUtils"
import FormSection from "../../common/FormSection"
import { checkoutConfig } from "@basset-la/components-products/dist/utils/config"
import ContactContainer from "../../contact/ContactContainer"
import NotFound from "../../NotFound"
import RequiredDocAlert from "../../requiredDoc/RequiredDocAlert"
import TermsAndConditions from "../../TermsAndConditions"
import * as model from "../../types"
import { saveEnabledStep } from "../helper"
import { Cart, CheckoutCombinedProduct } from "../types"
import styles from "./CombinedCheckout.styles"
import { useAuthUser, useConfig } from "@basset-la/components-commons"
import Message from "@basset-la/components-commons/dist/components/Message"
import CommonCheckoutRoomSection from "../../common/CommonCheckoutRoomSection"
import { validateRoomCompanionsSection } from "../../../utils/accommodationsUtils"
import WarningsDialog from "../../WarningsDialog/WarningsDialog"
import { RoomCompanionData } from "@basset-la/components-accommodations/dist/model/roomSection"

interface RouteMatch {
  id: string
}

const CombinedCheckout: React.FC = () => {
  const { i18n, t } = useTranslation(I18N_NS)
  const theme = useTheme()
  const history = useHistory()
  const match = useRouteMatch<RouteMatch>()
  const { search } = useLocation()

  const { config } = useConfig()
  const { userId } = useAuthUser()

  const [cart, setCart] = useState<Cart | null>(null)
  const [isBooking, setIsBooking] = useState<boolean>(false)
  const [hotelNotFound, setHotelNotFound] = useState<"" | "notFound" | "changeRate">("")
  const [showTermAndConditions, setShowTermAndConditions] = useState<boolean>(false)
  const [termsAndConditionAccepted, setTermsAndConditionAccepted] = useState<boolean>(false)
  const [paymentsURL, setPaymentsURL] = useState<string>()
  const [formConfig, setFormConfig] = useState<FormConfig | null>(null)
  const [product, setProduct] = useState<CheckoutCombinedProduct>()
  const [passengers, setPassengers] = useState<FlightsCheckoutPassenger[]>([])
  const [termAndConditions, setTermAndConditions] = useState<any>()
  const [billingInfo, setBillingInfo] = useState<model.BillingInformation>()
  const [contactInfo, setContactInfo] = useState<model.ContactInformation>()
  const [arrivalDate, setArrivalDate] = useState<Date>(new Date())
  const [geo, setGeo] = useState<model.Geo>()
  const [accommodation, setAccommodation] = useState<any>(null)
  const [roomGuests, setRoomGuests] = useState<any[]>([])

  const [openFlightChangeFareDialog, setOpenFlightChangeFareDialog] = useState(false)
  const [openHotelChangeFareDialog, setOpenHotelChangeFareDialog] = useState(false)
  const [hotelWarningsDialogStatus, setHotelWarningsDialogStatus] = useState("")
  const [changeFareCluster, setChangeFareCluster] = useState<ChangeFareCluster>()
  const [pendingReserve, setPendingReserve] = useState(false)
  const [continueReserve, setContinueReserve] = useState(false)
  const [originalFareCluster, setOriginalFareCluster] = useState<number>()
  const [roomCompanionsMap, setRoomCompanionsMap] = useState<Record<number, RoomCompanionData[]>>({})
  const [roomCompanionErrors, setRoomCompanionErrors] = useState<string[]>([])

  const isMobile = useMediaQuery("(max-width: 768px)")

  const createContactInformation = useCallback((countryCode: string) => {
    return {
      email: "",
      email_confirmation: "",
      telephone_type: checkoutConfig.config[countryCode].defaults.telephones.type,
      telephone_country_code: checkoutConfig.config[countryCode].defaults.telephones.country,
      telephone_area_code: checkoutConfig.config[countryCode].defaults.telephones.area,
      telephone_number: ""
    }
  }, [])

  const createPassengerType = useCallback((countryCode, type, quantity) => {
    let passengersList: FlightsCheckoutPassenger[] = []

    for (let x = 0; x < quantity; x++) {
      passengersList.push({
        type: type,
        firstname: "",
        lastname: "",
        gender: "",
        birthDay: 0,
        birthMonth: 0,
        birthYear: 0,
        document_type: checkoutConfig.config[countryCode].defaults.documentType,
        document_number: "",
        nationality: checkoutConfig.config[countryCode].defaults.nationality,
        document_issuing_country: checkoutConfig.config[countryCode].defaults.nationality,
        document_valid_thru: ""
      } as FlightsCheckoutPassenger)
    }

    return passengersList
  }, [])

  const createPassengers = useCallback(
    (countryCode: string, product) => {
      const price = product.upsell_fares[cart?.flight.selected_brand || 0].price

      let passengersList: FlightsCheckoutPassenger[] = []
      if (price) {
        if (price.adults) {
          passengersList = passengersList.concat(createPassengerType(countryCode, "ADT", price.adults.quantity))
        }
        if (price.seniors) {
          passengersList = passengersList.concat(createPassengerType(countryCode, "SRC", price.seniors.quantity))
        }
        if (price.disabled) {
          passengersList = passengersList.concat(createPassengerType(countryCode, "DIS", price.disabled.quantity))
        }
        if (price.children) {
          passengersList = passengersList.concat(createPassengerType(countryCode, "CHD", price.children.quantity))
        }
        if (price.infants) {
          passengersList = passengersList.concat(createPassengerType(countryCode, "INF", price.infants.quantity))
        }
      }

      return passengersList
    },
    [createPassengerType]
  )

  const createBillingInformation = useCallback((countryCode: string) => {
    return {
      fiscal_name: "",
      fiscal_id: "",
      fiscal_situation: checkoutConfig.config[countryCode].defaults.fiscalSituation,
      province: "",
      city: "",
      street: "",
      street_number: "",
      floor_number: "",
      building_number: "",
      postal_code: ""
    }
  }, [])

  const createRoomGuests = useCallback((rooms: Object[]) => {
    let roomGuests: any[] = []
    for (let index = 0; index < rooms.length; index++) {
      roomGuests.push({
        firstName: "",
        lastName: "",
        documentType: "",
        documentNumber: ""
      })
    }

    return roomGuests
  }, [])

  const retrieveCheckoutData = useCallback(
    async (c: Cart, fc: FormConfig, product?: CheckoutCombinedProduct) => {
      const lang = getLocale(i18n).substring(0, 2)
      const flightId = c.flight.flight_itinerary_id

      if (product) {
        try {
          const flightCluster = await getCluster(flightId, config.country, config.agency_id, userId, lang)

          if (isValidFare(flightCluster.original_fare)) {
            setChangeFareCluster(flightCluster)
            setOpenFlightChangeFareDialog(true)
            setOriginalFareCluster(flightCluster.original_fare)
          } else {
            //Handle change fare internally when prebooking is disabled and the cache
            //returns a itinerary that its fare was modified during reserve process because of a change fare error
            const pfcPriceTotal = product?.flightCluster?.upsell_fares
              ? product?.flightCluster?.upsell_fares[cart?.flight.selected_brand || 0].price.total
              : product?.flightCluster?.price.total

            const fcPriceTotal = flightCluster.upsell_fares
              ? flightCluster.upsell_fares[cart?.flight.selected_brand || 0].price.total
              : flightCluster.price.total

            const originalFare = isValidFare(pfcPriceTotal) ? pfcPriceTotal! : fcPriceTotal

            if (fcPriceTotal - originalFare !== 0) {
              setChangeFareCluster(flightCluster)
              setOpenFlightChangeFareDialog(true)
              setOriginalFareCluster(originalFare)
            } else {
              setProduct({
                flightCluster: flightCluster,
                rateCluster: product?.rateCluster
              })
              setContinueReserve(true)
            }
          }
        } catch (error) {
          setHotelNotFound("notFound")
        }
        return
      }

      let flightCluster: ChangeFareCluster | null = null
      let rateCluster: any = null
      let nf: "" | "notFound" | "changeRate" = ""

      try {
        const rateId = c.accommodation.rate_id
        const res = await Promise.all([
          getCluster(flightId, config.country, config.agency_id, userId, lang),
          getRate(rateId, config.agency_id, config.country)
        ])
        flightCluster = res[0]
        rateCluster = res[1]
      } catch (error) {
        nf = "notFound"
      }

      if (flightCluster && rateCluster) {
        const rateStatus = rateCluster.rates[0].status
        if (rateStatus !== "OK") {
          switch (rateStatus) {
            case "PRICE_CHANGE":
              setOpenHotelChangeFareDialog(true)
              break
            case "REFUNDABLE_CHANGE":
              setHotelWarningsDialogStatus("refundable_changed")
              break
            case "EXPIRATION_DATE_CHANGE":
              setHotelWarningsDialogStatus("expiration_date_changed")
              break
            case "SOLD_OUT":
              nf = "notFound"
              break
          }
          return
        }

        try {
          const [acc, countries] = await Promise.all([
            getAccommodation(rateCluster.rates[0].accommodation_id, lang, config.agency_id),
            getCountries(config.agency_id, lang)
          ])
          const states = !fc.billingInfo
            ? undefined
            : await getStatesByCountryCode(config.country, config.agency_id, lang)

          setAccommodation(acc)
          setBillingInfo(createBillingInformation(config.country))
          setContactInfo(createContactInformation(config.country))
          setGeo({
            countries: countries,
            states: states
          })

          setRoomGuests(createRoomGuests(rateCluster.rooms))
          setPassengers(createPassengers(config.country, flightCluster))
          setPaymentsURL(flightCluster.payments_url)
          setArrivalDate(getArrivalDate(flightCluster))

          const roomCompanionsMap: Record<number, any[]> = {}
          rateCluster.rooms.forEach((_, index) => (roomCompanionsMap[index] = []))

          setRoomCompanionsMap(roomCompanionsMap)
          setRoomCompanionErrors([])

          if (isValidFare(flightCluster.original_fare)) {
            setChangeFareCluster(flightCluster)
            setOriginalFareCluster(flightCluster.original_fare)
            setOpenFlightChangeFareDialog(true)
            setProduct({ flightCluster: null, rateCluster: rateCluster })
          } else {
            setProduct({
              flightCluster: flightCluster,
              rateCluster: rateCluster
            })
          }
        } catch (error) {
          nf = "notFound"
        }
      }

      setHotelNotFound(nf)
    },
    [
      i18n,
      config.country,
      createBillingInformation,
      createContactInformation,
      createRoomGuests,
      createPassengers,
      userId
    ]
  )

  const validateContactInfo = useCallback((): boolean => {
    if (!contactInfo) return false

    let isValid = true

    const contact = clone(contactInfo)
    if (!contact.email) {
      isValid = false
      contact.email_error = t("FlightsCheckout.emailError")
    } else {
      if (!validations.validateEmail(contact.email)) {
        isValid = false
        contact.email_error = t("FlightsCheckout.emailInvalid")
      } else {
        if (contact.email !== contact.email_confirmation) {
          isValid = false
          contact.email_confirmation_error = t("FlightsCheckout.emailConfirmation")
        }
      }
    }

    if (!contact.telephone_country_code) {
      isValid = false
      contact.telephone_country_code_error = t("FlightsCheckout.telephoneCountryCode")
    }

    if (!validations.validateNumeric(contact.telephone_country_code || "")) {
      isValid = false
      contact.telephone_number_error = t("FlightsCheckout.telephoneInvalid")
    }

    if (!contact.telephone_area_code) {
      isValid = false
      contact.telephone_area_code_error = t("FlightsCheckout.telephoneAreaCode")
    }

    if (!validations.validateAreaCode(contact.telephone_area_code || "")) {
      isValid = false
      contact.telephone_number_error = t("FlightsCheckout.telephoneInvalid")
    }

    if (!contact.telephone_number) {
      isValid = false
      contact.telephone_number_error = t("FlightsCheckout.telephoneNumber")
    }

    if (!validations.validateNumeric(contact.telephone_number || "")) {
      isValid = false
      contact.telephone_number_error = t("FlightsCheckout.telephoneInvalid")
    }

    if (!isValid) {
      setContactInfo(contact)
    }

    return isValid
  }, [contactInfo, config.country, t])

  const validateFields = useCallback(
    (config: any, info: any, prefix: string): boolean => {
      let isValid = true
      if (config.length > 0) {
        for (let i in config) {
          let name = config[i].name
          let regexValid = true

          if (config[i].regex) {
            let regex = new RegExp(config[i].regex, "g")
            regexValid = regex.test(info[name])
          }

          if (config[i].required && (!info[name] || !regexValid)) {
            isValid = false
            info[name + "_error"] = t(prefix + name)
          }
        }
      }

      return isValid
    },
    [t]
  )

  const validateBillingInfo = useCallback((): boolean => {
    if (!billingInfo) return false

    const billing = clone(billingInfo)
    const chkConfig = checkoutConfig.config[config.country]
    const identification = chkConfig.billing.identification ? chkConfig.billing.identification : []
    const addressStreet = chkConfig.billing.address_street ? chkConfig.billing.address_street : []
    const addressExtra = chkConfig.billing.address_extra ? chkConfig.billing.address_extra : []
    const fiscalId = chkConfig.billing.fiscal_id ? chkConfig.billing.fiscal_id : []
    const fiscalSituation = chkConfig.billing.fiscal_situation ? chkConfig.billing.fiscal_situation : []

    const cfg = identification
      .concat(addressStreet)
      .concat(addressExtra)
      .concat(fiscalId)
      .concat(fiscalSituation)

    let isValid = validateFields(cfg, billing, "FlightsCheckout.billingError.")
    if (!validations.validateFiscalID(billing.fiscal_id || "", billing.fiscal_situation || "", config.country)) {
      billing.fiscal_id_error = t("FlightsCheckout.billingError.fiscal_id")
      isValid = false
    }

    if (!isValid) {
      setBillingInfo(billing)
    }

    return isValid
  }, [billingInfo, config.country, t, validateFields])

  const validatePassengers = useCallback((): boolean => {
    const paxs = clone(passengers)
    const chkConfig = checkoutConfig.config[config.country]

    const validateNamesLength = validateFieldAirlines(product?.flightCluster as ChangeFareCluster)

    let isValid = true

    const passengersValidated = passengersValidations(config.country, paxs, arrivalDate, chkConfig, validateNamesLength)
    if (passengersValidated) {
      isValid = false
      setPassengers(passengersValidated)
    }

    return isValid
  }, [arrivalDate, passengers, config.country])

  const handleValidateRoomCompanionsSection = (): boolean => {
    const roomCompanionsMapCopy = clone(roomCompanionsMap)
    const roomCompanionErrorsCopy = clone(roomCompanionErrors)

    let valid = false

    if (product?.rateCluster) {
      valid = validateRoomCompanionsSection(
        roomCompanionsMapCopy,
        roomCompanionErrorsCopy,
        product.rateCluster,
        config.country,
        t
      )
    }

    setRoomCompanionsMap(roomCompanionsMapCopy)
    setRoomCompanionErrors(roomCompanionErrorsCopy)

    return valid
  }

  const validate = useCallback((): boolean => {
    const validPassengers = !formConfig?.passengers ? true : validatePassengers()
    const validBillingInfo =
      !formConfig?.billingInfo || formConfig.autocompleteBillingInfo ? true : validateBillingInfo()
    const validContactInfo = !formConfig?.contactInfo ? true : validateContactInfo()
    const validRoomCompanionsSection = handleValidateRoomCompanionsSection()

    return validPassengers && validBillingInfo && validContactInfo && validRoomCompanionsSection
  }, [formConfig, validateBillingInfo, validateContactInfo, validatePassengers])

  const handleReserveChangeFareError = useCallback(
    async (product?: CheckoutCombinedProduct) => {
      console.error("reserve change fare error")
      setPendingReserve(true)
      await retrieveCheckoutData(cart!!, formConfig!!, product)
    },
    [cart, formConfig, retrieveCheckoutData]
  )

  const validateAndPurchase = useCallback(
    async (product?: CheckoutCombinedProduct) => {
      if (!validate()) return
      const lang = getLocale(i18n).substring(0, 2)
      const req = createCombinedPurchaseModel(
        cart!.flight.flight_itinerary_id,
        passengers,
        cart!.accommodation.rate_id,
        roomGuests,
        billingInfo,
        contactInfo,
        config.country,
        formConfig,
        lang,
        product!.flightCluster!!,
        cart?.flight.selected_brand || 0,
        product!.rateCluster.rooms.map(r => r.id),
        roomCompanionsMap
      )

      setIsBooking(true)

      try {
        const resProc = await createReservationAsync(req, userId, config.country, config.agency_id)

        const interval = setInterval(async () => {
          const reservationProcess = await getReservationProcess(resProc.id, userId, config.country, config.agency_id)
          switch (reservationProcess.status) {
            case "OK":
              if (paymentsURL) {
                let url = paymentsURL.replace("{id}", `${reservationProcess.reservation_id}`)
                const { _ga } = qs.parse(window.location.search.substr(1))

                if (_ga) {
                  url = url.replace("{_ga}", _ga)
                } else {
                  const ga = ReactGA.ga()
                  ga(function(tracker) {
                    let clientId = tracker.get("clientId")
                    url = url.replace("{_ga}", clientId)
                  })
                }
                window.location.href = url
              } else {
                history.push(`/checkout/combined/reservations/${reservationProcess.reservation_id}/thanks`)
              }

              clearInterval(interval)
              break
            case "ERROR":
              console.error(reservationProcess.error_message)
              setHotelNotFound("notFound")
              clearInterval(interval)
              break
            case "CHANGE_RATE":
              console.error(reservationProcess.error_message)
              setHotelNotFound("changeRate")
              clearInterval(interval)
              break
            case "CHANGE_FARE":
              clearInterval(interval)
              handleReserveChangeFareError(product)
              break
            default:
              console.log("Still booking...")
              break
          }
        }, 1000)
      } catch (err) {
        console.error(err)
        setHotelNotFound("notFound")
      }
    },
    [
      billingInfo,
      cart,
      contactInfo,
      formConfig,
      handleReserveChangeFareError,
      validate,
      history,
      i18n,
      passengers,
      paymentsURL,
      product,
      roomGuests,
      config.country,
      userId
    ]
  )

  useEffect(() => {
    const loadCart = async () => {
      try {
        setIsBooking(true)

        setFormConfig(getFormConfig(config.checkout_form_config))
        setTermAndConditions(config.brand.terms_and_conditions)

        const result = await getCart(config, match.params.id)
        setCart(result)
      } catch (e) {
        setHotelNotFound("notFound")
      } finally {
        setIsBooking(false)
      }
    }

    if (!cart) {
      loadCart()
    }
  }, [i18n.language, cart, match.params.id])

  useEffect(() => {
    saveEnabledStep("checkout")
    if (cart && formConfig) {
      retrieveCheckoutData(cart, formConfig)
    }
  }, [retrieveCheckoutData, cart, formConfig])

  useEffect(() => {
    if (continueReserve) {
      setPendingReserve(false)
      setContinueReserve(false)
      validateAndPurchase(product)
    }
  }, [continueReserve, product, validateAndPurchase])

  const onPassengerChange = (i: number, field: string, value: string) => {
    let regex
    if (field === "firstname" || field === "lastname") {
      regex = /^[a-zA-Z\sñáéíóúÁÉÍÓÚ]*$/
      if (!regex.test(value)) {
        value = passengers[i][field]
      }
    }
    if (field === "document_number") {
      regex = /^[a-zA-Z0-9]*$/
      if (!regex.test(value)) {
        value = passengers[i][field]
      }
    }
    if (field === "document_valid_thru") {
      regex = /^[\d-]*$/
      if (value !== "" && !regex.test(value)) {
        value = passengers[i][field]
      }
      if (value.length === 11) value = passengers[i][field]
      if (value.length === 8) {
        if (value.charAt(7) === "-") value = value.substr(0, 7)
        else value = value.slice(0, -1) + "-" + value.slice(-1)
      }
      if (value.length === 5) {
        if (value.charAt(4) === "-") value = value.substr(0, 4)
        else value = value.slice(0, -1) + "-" + value.slice(-1)
      }
      if (
        (passengers[i][field].length === 3 && value.length === 4) ||
        (passengers[i][field].length === 6 && value.length === 7)
      ) {
        value = value + "-"
      }
    }

    const passenger = { ...passengers[i], [field]: value }
    if (passenger[field + "_error"]) delete passenger[field + "_error"]
    if (passenger["birthDate_error"]) delete passenger["birthDate_error"]

    setPassengers([...passengers.slice(0, i), passenger, ...passengers.slice(i + 1, passengers.length)])
  }

  const onChange = (target: "billingInfo" | "contactInfo") => (field: string, value: string) => {
    let target_billing_contact
    switch (target) {
      case "billingInfo":
        target_billing_contact = billingInfo
        break
      case "contactInfo":
        target_billing_contact = contactInfo
        break
    }

    let regex
    if (field === "fiscal_name" || field === "city") {
      regex = /^[a-zA-Z\sñáéíóúÁÉÍÓÚ]*$/
      if (!regex.test(value)) {
        value = target_billing_contact[field]
      }
    }
    if (field === "street") {
      regex = /^[a-zA-Z0-9\s.,#-]*$/
      if (!regex.test(value)) {
        value = target_billing_contact[field]
      }
    }
    if (field === "street_number" || field === "floor_number" || field === "building_number") {
      regex = /^[a-zA-Z0-9\s-]*$/
      if (!regex.test(value)) {
        value = target_billing_contact[field]
      }
    }
    if (field === "postal_code") {
      regex = /^[\d-]*$/
      if (!regex.test(value)) {
        value = target_billing_contact[field]
      }
    }

    if (field === "email" || field === "email_confirmation") {
      regex = /^[a-zA-Z0-9@._%+-]*$/
      if (!regex.test(value)) {
        value = target_billing_contact[field]
      }
    }

    if (field === "telephone_country_code" || field === "telephone_area_code" || field === "telephone_number") {
      regex = /^[\d]*$/
      if (!regex.test(value)) {
        value = target_billing_contact[field]
      }
    }

    switch (target) {
      case "billingInfo":
        const billing = { ...billingInfo, [field]: value }
        if (billing[field + "_error"]) delete billing[field + "_error"]
        setBillingInfo(billing)
        break

      case "contactInfo":
        const contact = { ...contactInfo, [field]: value }
        if (contact[field + "_error"]) delete contact[field + "_error"]
        setContactInfo(contact)
        break
    }
  }

  const hideTermsAndConditions = () => {
    setShowTermAndConditions(false)
  }

  const handleShowTermAndConditions = () => {
    setShowTermAndConditions(true)
  }

  const onChangeTermAndConditions = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    setTermsAndConditionAccepted(checked)
  }

  const getArrivalDate = (cluster: FlightClusterModel): Date => {
    if (cluster.segments.length > 0) {
      const lastSegment = cluster.segments[cluster.segments.length - 1]

      if (lastSegment.options.length > 0) {
        const lastOption = lastSegment.options[lastSegment.options.length - 1]

        return moment(lastOption.arrival_date + " " + lastOption.arrival_time, "YYYY-MM-DD HH:mm")
          .utc()
          .toDate()
      }
    }

    return new Date()
  }

  const handleStepClick = (type: StepType) => {
    let path = ""
    switch (type) {
      case "flight":
        path = `/combined/flights${search}`
        break
      case "hotel":
        path = `/combined/accommodations${search}`
        break
      case "checkout":
        path = `/checkout/combined${search}`
        break
    }
    window.location.href = path
  }

  const handleBackToFlightSearch = () => {
    handleStepClick("flight")
  }

  const handleAcceptNewFlightFare = () => {
    setProduct({
      ...product!,
      flightCluster: changeFareCluster!!
    })
    setChangeFareCluster(undefined)
    setOriginalFareCluster(undefined)
    setOpenFlightChangeFareDialog(false)
    if (pendingReserve) {
      setContinueReserve(true)
    }
  }

  const handleOnUpdateRoomGuests = (roomGuests: any): void => {
    setRoomGuests(roomGuests)
  }

  const handleOnUpdateRoomCompanionsMap = (roomCompanionsMap: Record<number, RoomCompanionData[]>): void => {
    setRoomCompanionsMap(roomCompanionsMap)
  }

  const handleAcceptNewHotelFare = () => {
    setOpenHotelChangeFareDialog(false)
  }

  const handleAcceptWarningDialog = () => {
    setHotelWarningsDialogStatus("")
  }

  const handleBackToHotelSearch = () => {
    handleStepClick("hotel")
  }

  const handleCancelWarningDialog = () => {
    setHotelWarningsDialogStatus("")
    handleBackToHotelSearch()
  }

  let requiredDocAlert: any[] = []
  const chkConfig = checkoutConfig.config[config.country]

  if (hotelNotFound !== "") {
    return (
      <div className={`${styles.background}`}>
        <div className={`${styles.checkout}`}>
          <div className={`${styles.notFoundContainer}`}>
            <NotFound
              description={t<string>(`CombinedCheckout.${hotelNotFound}`)}
              onBackToSearch={handleBackToFlightSearch}
            />
          </div>
        </div>
      </div>
    )
  }

  if (!product?.flightCluster || !product?.rateCluster || !geo || !formConfig) {
    return (
      <div id="mainContainer" className={`${styles.background}`}>
        <ProductLoader is_mobile={isMobile} product_variant="FLIGHTS_CHECKOUT" variant="WEB" />
      </div>
    )
  }

  if (formConfig.passengerDisclaimers.enabled) {
    const email = formConfig.passengerDisclaimers.email
    const passengerTypes = [...new Set(passengers.map(passenger => passenger.type))]
    for (let type of passengerTypes) {
      if (type === "SRC") {
        requiredDocAlert.push(<RequiredDocAlert type="SRC" email={email} />)
      } else if (type === "DIS") {
        requiredDocAlert.push(<RequiredDocAlert type="DIS" email={email} />)
      }
    }
  }

  let childrenAges: Record<number, number> = {}
  let adults = 0

  if (product.rateCluster) {
    for (let room of product.rateCluster.rooms) {
      if (room.child_ages) {
        adults += room.capacity - room.child_ages.length

        for (let childAge of room.child_ages) {
          if (!childrenAges[childAge]) {
            childrenAges[childAge] = 0
          }
          childrenAges[childAge]++
        }
      } else {
        adults += room.capacity
      }
    }
  }

  return (
    <div id="mainContainer" className={`${styles.background}`}>
      {changeFareCluster && isValidFare(originalFareCluster) && (
        <FlightChangedFareDialog
          open={openFlightChangeFareDialog}
          isMobile={isMobile}
          closeOnlyUserAction={true}
          currency={changeFareCluster.price.currency}
          newPrice={changeFareCluster.price.total}
          oldPrice={originalFareCluster!}
          handleAcceptNewFare={handleAcceptNewFlightFare}
          handleRejectNewFare={handleBackToFlightSearch}
        />
      )}
      {openHotelChangeFareDialog && product.rateCluster?.rates?.length && product.rateCluster?.oldFare && (
        <HotelChangedFareDialog
          open={openHotelChangeFareDialog}
          currency={product.rateCluster.rates[0]!.fare!.currency}
          newPrice={product.rateCluster.rates[0]!.fare!.total}
          oldPrice={product.rateCluster.oldFare.total}
          closeOnlyUserAction
          handleAcceptNewFare={handleAcceptNewHotelFare}
          handleRejectNewFare={handleBackToHotelSearch}
        />
      )}

      {isBooking && <ProductLoader is_mobile={isMobile} product_variant="FLIGHTS_PAYMENT" variant="WEB" />}

      {!isBooking && (
        <div className={`${styles.checkout}`}>
          {cart && (
            <Stepper
              accommodation={{
                hotelName: cart.accommodation.name,
                totalRooms: cart.accommodation.total_rooms
              }}
              flight={{
                origin: cart.flight.origin,
                destination: cart.flight.destination,
                from: moment(cart.flight.from),
                to: moment(cart.flight.to),
                totalPassengers: cart.flight.total_passengers
              }}
              stickyBoundary={{
                top: 0,
                bottom: "#mainContainer"
              }}
              currentStep="checkout"
              enabledStep="checkout"
              onStepClick={handleStepClick}
            />
          )}
          {product.flightCluster && product.rateCluster && (
            <>
              <p className={`${styles.title(theme)}`}>{t<string>("FlightsCheckout.title")}</p>
              <div className={`${styles.mainContainer}`}>
                <div className={`${styles.detailContainer}`}>
                  <div className={`${styles.priceBreakdownContainer}`}>
                    <PriceboxBreakdownCheckoutWeb
                      rooms={product.rateCluster.rooms.length}
                      guests={adults}
                      flightPrice={
                        product.flightCluster.upsell_fares
                          ? product.flightCluster.upsell_fares[cart?.flight.selected_brand || 0].price
                          : product.flightCluster.price
                      }
                      accommodationPrice={product.rateCluster.rates[0].fare}
                    />
                  </div>
                  <div className={styles.productContainer}>
                    <FlightCluster variant="B2C" mini={true} cluster={product.flightCluster} />
                  </div>
                  <div className={styles.productContainer}>
                    <HotelBookingSummary
                      adults={adults}
                      checkin={
                        product.rateCluster ? moment(product.rateCluster.rates[0].checkin.split("T")[0]) : moment()
                      }
                      checkout={
                        product.rateCluster ? moment(product.rateCluster.rates[0].checkout.split("T")[0]) : moment()
                      }
                      destination={accommodation.zone.city.name}
                      hotelImage={accommodation.images[0].url}
                      hotelName={accommodation.name}
                      childrenAges={childrenAges}
                      roomName={product.rateCluster.rooms[0].name}
                      roomsCount={product.rateCluster.rooms.length}
                      checkinTime={accommodation.checkin?.time || ""}
                      checkoutTime={accommodation.checkout?.time || ""}
                    />
                    <div className={styles.penaltiesAlert}>
                      <CancelPenaltiesAlert
                        variant="ALERT"
                        cancelPenalties={product.rateCluster.rates[0].cancel_penalties}
                        refundable={product.rateCluster.rates[0].refundable}
                        expirationDate={product.rateCluster.rates[0].expiration_date}
                        customExpirationDate={product.rateCluster.rates[0].custom_expiration_date}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.formContainer}>
                  {requiredDocAlert}
                  {formConfig.passengers && (
                    <FormSection title={t<string>("FlightsCheckout.passengersTitle")}>
                      <PassengersContainer
                        onChange={onPassengerChange}
                        countries={geo.countries}
                        passengers={passengers}
                        maxDate={arrivalDate}
                        checkoutConfig={chkConfig}
                      />
                    </FormSection>
                  )}
                  <FormSection>
                    <BaggageAllowance
                      cluster={product.flightCluster as Cluster}
                      selectedBrand={cart!.flight.selected_brand}
                    />
                  </FormSection>
                  <FormSection title={t<string>("AccommodationsCheckout.hosts")}>
                    <CommonCheckoutRoomSection
                      roomGuests={roomGuests}
                      roomCapacities={product.rateCluster.rooms.map(r => r.capacity - 1)}
                      roomChildrenCount={product.rateCluster.rooms.map(r => r.child_ages?.length || 0)}
                      roomCompanionsMap={roomCompanionsMap}
                      roomCompanionErrors={roomCompanionErrors}
                      chkConfig={chkConfig}
                      onUpdateRoomGuests={handleOnUpdateRoomGuests}
                      onUpdateRoomCompanionsMap={handleOnUpdateRoomCompanionsMap}
                    />
                  </FormSection>
                  {formConfig.billingInfo && (
                    <FormSection title={t<string>("FlightsCheckout.billingTitle")}>
                      {formConfig.autocompleteBillingInfo && (
                        <Message open variant="fixed" action="info" message={t("FormConfig.autocompleteBillingInfo")} />
                      )}
                      <BillingContainer
                        onChange={onChange("billingInfo")}
                        billingInfo={billingInfo}
                        checkoutConfig={chkConfig}
                        geo={geo}
                      />
                    </FormSection>
                  )}
                  {formConfig.contactInfo && (
                    <FormSection title={t<string>("FlightsCheckout.contactTitle")}>
                      <ContactContainer onChange={onChange("contactInfo")} contact={contactInfo} />
                    </FormSection>
                  )}
                  <div className={`${styles.termAndConditions(theme)}`}>
                    <Checkbox
                      classes={{
                        root: `${styles.checkbox}`,
                        checked: `${styles.checkboxChecked(theme)}`
                      }}
                      checked={termsAndConditionAccepted}
                      onChange={onChangeTermAndConditions}
                    />
                    <p>
                      {t<string>("FlightsCheckout.termAndConditionsAccept")}{" "}
                      <button onClick={handleShowTermAndConditions}>
                        {t<string>("FlightsCheckout.termAndConditionsLink")}
                      </button>
                    </p>
                  </div>
                  <Button
                    id="buy-button"
                    variant={"contained"}
                    classes={{ root: `${styles.button(theme)}` }}
                    disabled={isBooking || !termsAndConditionAccepted}
                    onClick={() => validateAndPurchase(product)}
                  >
                    {paymentsURL ? t<string>("FlightsCheckout.buyButton") : t<string>("FlightsCheckout.reserveButton")}
                  </Button>
                  <TermsAndConditions
                    showTermAndConditions={showTermAndConditions}
                    hideTermsAndConditions={hideTermsAndConditions}
                    termAndConditions={termAndConditions}
                    showProviderTerms={false}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}

      <WarningsDialog
        open={hotelWarningsDialogStatus !== ""}
        hotelName={product.rateCluster.rooms[0].name}
        status={hotelWarningsDialogStatus}
        onCancel={handleCancelWarningDialog}
        onAccept={handleAcceptWarningDialog}
      />
    </div>
  )
}

export default CombinedCheckout
